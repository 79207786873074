import OverlayFromJson from "../Overlay/OverlayFromJson.js";
import Auth from "../../utils/Auth.js";
import CurrentUser from "../../data/LocalStorage/CurrentUser.js";


/**
 * Static class for the main web application entry point
 * @author Glen Birkbeck
 */
export default class ProfileForm {

    static #callback;

    static async Show(callbackCB) {
        this.#callback = callbackCB; // store this for after we have processed the login
        let hasAccessToken = false
        let accessToken = await CurrentUser.AccessToken();
        let userDetails = await CurrentUser.Details();
        if (accessToken) hasAccessToken = true;
        let overlayOptions = {
            title: "Profile",
            body: [
                {
                    value: 'First name: ' + userDetails.firstName,
                    type: "message"
                },
                {
                    value: 'Email: ' + userDetails.email,
                    type: "message"
                },
                {
                    value: 'password',
                    type: "message"
                },
                {value: 'HasAccessToken: ' + hasAccessToken, type: 'message'}
            ],
            buttons: [
                { action: "ok", title: "Save" },
                { action: "logout", title: "Logout" }
            ]
        };
        let uiOverlay = new OverlayFromJson();
        uiOverlay.Render(overlayOptions, this.#handleFormSubmission);
    }

    // Uses arrow function form to retain "this"
    static #handleFormSubmission = async (jqButton, uiOverlay) => {
        let overlayResultsJson = uiOverlay.GetAllInputs()
        console.log('jqButton: ', jqButton)
        console.log('overlayResultsJson: ', overlayResultsJson)
        let action = jqButton.data('action');
        if (action=='logout') {
            Auth.Logout();
            uiOverlay.Hide();
            window.location.reload();
        }
    }


}
