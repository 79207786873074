// For capacitor we import, but for a PWA we just use our own wrapper class Preferences to be window.localStorage
import Preferences from "./Preferences.js";
//import { Preferences } from '@capacitor/preferences'; 

export default class LocalStorage {

    static #isFirstRun = false;

    static #PREF_GROUP_NAME = 'AsGuard';

    static Init = async () => { // Init called by top level WebApp.js
        await Preferences.configure({ group: this.#PREF_GROUP_NAME });
    }

    static IsFirstRun = () => {
        return this.#isFirstRun;
    }

    static ClearTable = async(tableName) => {
        return await Preferences.remove({ key: tableName });
    }

    static ResetAll = async () => {
        let otherKeys = (await Preferences.keys()).keys;
        console.log('otherKeys: ', otherKeys);
        for (let i = 0; i < otherKeys.length; i++) {
            await Preferences.remove({ key: otherKeys[i] });
        }
    }

    static SaveRecord = async (tableName, recordJson) => {
        return await this.#saveRecordInJsonArray(tableName, recordJson);
    }

    static GetAllRecords = async (tableName) => {
        return await this.#getAllRecordsAsJsonArray(tableName);
    }

    static Uuidv4 = () => {
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
        );
    }

    static GetRecordByUuidv4 = async(tableName, uuidv4) => {
        let resultRecJson = null;
        let jsonRecsArray = await this.#getAllRecordsAsJsonArray(tableName);
        resultRecJson = jsonRecsArray.find( (el) => {
            return (el.uuidv4 == uuidv4);
        });
        return resultRecJson;
    }

    static SaveTable = async(tableName, jsonArray) => {
        await Preferences.set({
            key: tableName,
            value: JSON.stringify(jsonArray)
        });
    }

    // internals

    // internal records
    // Note trying these as simple key/value for simplicity but may need to use a different strategy
    // if it becomes to processor/memory intensive, Apr 2024

    static #getAllRecordsAsJsonArray = async (tableName) => {
        let { value } = await Preferences.get({ key: tableName });
        if ((typeof value === 'undefined') || (!value)) {
            value = "[]";
        }
        let jsonArray = JSON.parse(value);
        return jsonArray;
    }

    static #saveRecordInJsonArray = async (tableName, recJson) => {
        if ('uuidv4' in recJson === false) recJson.uuidv4 = this.Uuidv4();
        if ('createdAt' in recJson === false) recJson.createdAt = Date.now();
        recJson.modifiedAt = Date.now();
        let jsonArray = await this.#getAllRecordsAsJsonArray(tableName);
        jsonArray.unshift(recJson);
        await Preferences.set({
            key: tableName,
            value: JSON.stringify(jsonArray)
        });
        console.log('Saved recJson: ',recJson );
        return jsonArray;
    }

    // examples
/*
    static #setName = async () => {
        await Preferences.set({
            key: 'name',
            value: 'Max',
        });
    };

    static #checkName = async () => {
        const { value } = await Preferences.get({ key: 'name' });

        console.log(`Hello ${value}!`);
    };

    static #removeName = async () => {
        await Preferences.remove({ key: 'name' });
    };*/
}
