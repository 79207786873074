/**
 * Static class that replicates CapacitorJS Preferences class to simplify switching between them
 * For the web PWA app and the CapacitorJS app
 * 
 * Note: functions defined as async to be consistent with CapacitorJS Preferences class
 * @author Glen Birkbeck
 */

export default class Preferences {

    static #namePrefix = '';
    static #separator = '.';

    /*
     * Expects { group: prefixName }
     */
    static async configure(configJson) {
        if ('group' in configJson) {
            this.#namePrefix = configJson.group;
        } else {
            console.warn('Preferences.configure called without {group: "foo"}');
        }
    }

    static async get(getOptions) {
        if ('key' in getOptions) {
            return {value: localStorage.getItem(this.#internalName(getOptions.key))};
        } else {
            console.warn('Preferences.get called without {key: "foo"}');
        }
    }

    static async set(setOptions) {
        if ( ('key' in setOptions) && ('value' in setOptions)) {
            localStorage.setItem(this.#internalName(setOptions.key), setOptions.value);

        } else {
            console.warn('Preferences.set called without one or more of the following{key: "foo", value: "bar"}');
        }
    }

    static async remove(removeOptions) {
        if ('key' in removeOptions) {
            localStorage.removeItem(this.#internalName(removeOptions.key));
        } else {
            console.warn('Preferences.remove called without {key: "foo"}');
        }
    }

    static async keys() {
        let results = [];
        let startsWithPrefix = this.#namePrefix + this.#separator;
        for (var key in localStorage) {
            if (key.startsWith(startsWithPrefix)) {
                results.push(key.slice(startsWithPrefix.length));
            }
        }
        return {keys: results};
    }

    static async clear() {
        let startsWithPrefix = this.#namePrefix + this.#separator;
        for (var storedKey in localStorage) {
            if (storedKey.startsWith(startsWithPrefix)) {
                localStorage.removeItem(storedKey);
            }
        }
    }


    // internals

    static #internalName(name) {
        return this.#namePrefix + this.#separator + name;
    }

}