import ConversationHistory from '../dataObjects/ConversationHistory.js';
import AdminPanelBase from '../../core/ui/panels/Admin/Shared/AdminPanelBase.js';

export default class ConversationHistoryAdmin extends AdminPanelBase {
    
    constructor(where, whatJson) {
        let buttons = '';
        whatJson = whatJson || {};
        const tableSummary = ConversationHistory.GetTableSummary();
        whatJson.sectionTitle = tableSummary.displayNamePlural;
        whatJson.sectionDescription = tableSummary.description;
        super(where, whatJson, ConversationHistory, buttons);
    }

}