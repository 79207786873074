import LocalStorage from "../LocalStorage.js";

// currently a very simplistic implementation
// under the hood it just rewrites the entire table whenever a record is added/updated/deleted
// will need to come back to this!

/*
    TableBase provides the following public functions:
        .NewUuidv4()
        .GetAll()
        .GetByUuidv4(uuidv4)
        .InsertRecord(recJson)
        .UpdateRecord(recJsonToUpdate)
        .DeleteRecord(recJson)
        .SaveAll(jsonArray)
        .ClearAll()
        // .GenerateUuidv4(); changed to internal function
 */

export default class TableBase {
    static TABLE_NAME = 'child/sub class must define this';

    static NewUuidv4() {
        return this.GenerateUuidv4();
    }
    
    static async GetAll() {
        return await LocalStorage.GetAllRecords(this.TABLE_NAME);
    }

    static async GetAllChangesWithinTimeFrame(startDt, endDt) {
        console.log('ToDo: ' + this.TABLE_NAME + '.GetAllChangesWithinTimeFrame()');
        return [];
    }

    static async GetByUuidv4(uuidv4) {
        let allRecsArray = await LocalStorage.GetAllRecords(this.TABLE_NAME, uuidv4);
        return allRecsArray.find((el) => el.uuidv4 == uuidv4);
    }

    static async InsertRecord(recJson) {
        let arrayOfJson = await this.GetAll();
        recJson.uuidv4 = this.GenerateUuidv4();
        recJson.createdAt = Date.now();
        recJson.modifiedAt = Date.now();
        arrayOfJson.push(recJson);
        return await this.SaveAll(arrayOfJson);
    }

    static async UpdateRecord(recJsonToUpdate) {
        let arrayOfJson = await this.GetAll();
        for (let i = 0; i < arrayOfJson.length; i++) {
            let recJson = arrayOfJson[i];
            if (recJson.uuidv4 == recJsonToUpdate.uuidv4) {
                recJsonToUpdate.modifiedAt = Date.now();
                arrayOfJson[i] = recJsonToUpdate;
                break;
            }
        }
        return await this.SaveAll(arrayOfJson);
    }

    static async DeleteRecord(recJson) {
       await this.DeleteRecordByUuidv4(recJson.uuidv4);
    }

    static async DeleteRecordByUuidv4(uuidv4) {
        if (uuidv4) {
            let arrayOfJson = await this.GetAll();
            let indexToRemove = -1;
            for (let i = 0; i < arrayOfJson.length; i++) {
                if (arrayOfJson[i].uuidv4 == uuidv4) {
                    indexToRemove = i;
                    break;
                }
            }
            if (indexToRemove != -1) {
                arrayOfJson.splice(indexToRemove, 1);
                await this.SaveAll(arrayOfJson);

            } else {
                console.warn('Error TableBase.DeleteRecord, no record with matching uuidv4: ', recJson);
            }
        } else {
            console.warn('Error TableBase.DeleteRecord, no uuidv4 provided: ', recJson);
        }
    }

    static async SaveAll(arrayOfJson) {
        return await LocalStorage.SaveTable(this.TABLE_NAME, arrayOfJson);
    }

    static async Clear() { 
        return await LocalStorage.ClearTable(this.TABLE_NAME);
    }
    static async ClearAll() {
        return await LocalStorage.ClearTable(this.TABLE_NAME);
    }

    static GenerateUuidv4 = () => {
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
        );
    }

}