import UiBase from '../UiBase.js';
import Template from './Template.html';
import CurrentUser from '../../data/LocalStorage/CurrentUser.js';
import LlmController from "../../utils/LlmController.js";
import AudioPlayer from "../../utils/AudioPlayer.js";
import AudioRecorder from "../../utils/AudioRecorder.js";
import HistoryController from "../../utils/HistoryController.js";

export default class ChatPage extends UiBase {
    static isInitialised = false;
    static #divId = 'ChatDiv';
    static #speakResponses = true;

    static Init = async () => {
        let jqContent = $('#Content');
        jqContent.html(Template);
        if (!this.isInitialised) { // stuff we only want to do when the page is first loaded
            let firstName = await CurrentUser.FirstName();
            if (firstName && (firstName != '')) {
                HistoryController.SetUserName(firstName);
            }
            AudioRecorder.Init();
            this.isInitialised = true;
            console.log('ChatPage Initialised');
        }
        this.#configureEventHandlers();
    }

    static Show() {
        $('#'+this.#divId).show();
        this.#configureEventHandlers();
    }

    static async ProcessUserMsg(userMsg) {
        await this.#processUserMsg(userMsg);
    }

    static async ProcessError(errorJson) {
        HistoryController.AddErrorMessage(errorJson.error);
    }

    // internals

    static #configureEventHandlers = () => {
        let self = this;
        // select all text in input box when it is clicked
        $('#UserTextInput').off('click');
        $('#UserTextInput').on('click', function () {
            event.currentTarget.select();
        });
        // on input box <enter> pressed, process the user's message
        $('#UserTextInput').off('keypress');
        $('#UserTextInput').on('keypress', async function (e) {
            var code = e.keyCode || e.which;
            if (code == 13) {
                $('#UserTextBtn').click();
            }
        });
        $('#UserTextBtn').off('click');
        $('#UserTextBtn').on('click', async function () {
            let userMsg = $('#UserTextInput').val();
            await self.ProcessUserMsg(userMsg);
        });
        $('#MobileNav2CloseBtn').off('click');
        $('#MobileNav2CloseBtn').on('click', function () {
            $('#MobileNav').hide();
        });
    }

    static async #processUserMsg(userMsg) {
        HistoryController.AddUserMessage(userMsg);
        let responseJson = await LlmController.SendMsgToAi(userMsg);
        let msgToPlayUser = '';
        let msgToShowUser = '';
        if (responseJson.error && !responseJson.msg) {
            msgToPlayUser = 'An error occurred';
            if (responseJson.error) msgToShowUser = responseJson.error;
            else msgToShowUser = 'An error occurred';
            HistoryController.AddErrorMessage(msgToShowUser);
        } else {
            msgToPlayUser = responseJson.msg;
            HistoryController.AddAsguardResponse(msgToPlayUser);
        }
        if (this.#speakResponses) AudioPlayer.ConvertTextAndPlay(msgToPlayUser);
    }

}