//import { UiBase } from '../UiBase.js';
import UiBase from "./UiBase";

export default class PageBase extends UiBase {
//export class PageBase extends UiBase {
    /**
     * @class
     * @name PageBase
     * @description Base class for pages. Subclass of UiBase.
     */

    /**
     * @param {string} whereDivId - target div id, defaults to main#Content
     */
    constructor(whereDivId) {
        whereDivId = whereDivId || 'main#Content';
        super(whereDivId);
    }

    /**
     * 
     * @param {string[]} sectionsArray - array of section names to be used as div ids
     * @returns html to inject into the main area of the page
     */
    MakePageSectionsHtml(sectionsArray) {
        let sectionsHtml = '';
        for (let i=0;i<sectionsArray.length;i++) {
            let sectionDivId = sectionsArray[i];
            sectionsHtml += '<section id=' + sectionDivId + '></section>';
        }
        return sectionsHtml;
    }

    //async #populateSections () {} // subclass defines this    

}