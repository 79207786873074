import UiBase from "../UiBase.js";
import Template from './Template.html';
import CloseButton from './CloseButton.html';

export default class Overlay extends UiBase {
    #TITLE_DIV_SUFFIX = '_OverlayTitle';
    #CONTENT_DIV_SUFFIX = '_OverlayContent';
    #CONTROLS_DIV_SUFFIX = '_OverlayControls';
    #BUTTONS_CLASS = '.clickableElement';
    Callback = null;
    Height = 1; // valid options are 1, 2 or 3 for z-index 1000, 2000, 3000

    constructor(whereSelectorString, optionalWhatJson, optionalHeight) {
        let whatJson = optionalWhatJson || {};
        let zHeight = optionalHeight || 1;
        whereSelectorString = whereSelectorString || '#FixedOverlayContainer' + zHeight;
        super(whereSelectorString, whatJson, Template);
        this.Height = zHeight;
    }

    Render(title, body, optionalButtons, optionalCallback) {
        super.Render();
        this.Callback = optionalCallback;
        $('#' + this.Id + this.#TITLE_DIV_SUFFIX).html(title);
        $('#' + this.Id + this.#CONTENT_DIV_SUFFIX).html('<div class="w-full sm:mx-auto flex flex-col justify-center align-middle">' + body + '</div>');
        let buttons = optionalButtons || '';
        buttons += CloseButton
        $('#' + this.Id + this.#CONTROLS_DIV_SUFFIX).html(buttons); // default is just a cancel/close button
        $('#' + this.OVERLAY_ID).fadeIn();
        let jqInputs = this.UiAsJquery().find('input');
        if (jqInputs.length>0) $(jqInputs[0]).focus();
        this.#configureEventHandlers();
    }

    GetJqInputsByClass(className) {
        let jqInputs = $('#' + this.Id).find('input.' + className);
        return jqInputs;
    }

    GetAllInputs() {
        // returns JSON key/value pairs for all inputs that have a data-column attribute
        let htmlInputColumnValues = this.#getHtmlInputColumnValues();
        let htmlInputRadioColumnValues = this.#getHtmlInputRadioColumnValues();
        let htmlSelectColumnValues = this.#getHtmlSelectColumnValues();
        let htmlTextareaColumnValues = this.#getHtmlTextareaColumnValues();
        return Object.assign(htmlInputColumnValues, htmlInputRadioColumnValues, htmlSelectColumnValues, htmlTextareaColumnValues);
    }

    #configureEventHandlers = () => {
        let self = this;
        let jqButtons = $('#' + this.Id).find(this.#BUTTONS_CLASS);
        jqButtons.off('click');
        jqButtons.on('click', async function (e) {
            let jqButton = $(this);
            let action = jqButton.data('action');
            console.log('Button action: ', action);
            if (action == 'Close') {
                self.Hide();
            } else if (self.Callback) {
                await self.Callback(jqButton, self, action);
            } else {
                console.log('Overlay error: No handler for custom button click');
            }
        });
    }

    #getHtmlInputColumnValues() {
        // returns JSON key/value pairs for all input elements, except radios, that have a data-column attribute
        let resultColsValues = {};
        let jqInputs = $('#' + this.Id).find('input');
        for (let i = 0; i < jqInputs.length; i++) {
            let jqInput = $(jqInputs[i]);
            // originally we used data-name="columnName" but have moved to data-column="columnName" to be less ambiguous
            // retained support for name for backwards compatibility
            let colName = jqInput.data('column');
            if (!colName) {
                colName = jqInput.data('name');
            }
            if (!colName) {
                console.warn('Input with no data-column or data-name attribute:', jqInput);
            } else {
                let inputValue = jqInput.val();
                if ((inputValue) && (inputValue.length > 0)) {
                    if (jqInput.attr('type') != "radio") {
                        resultColsValues[colName] = inputValue;
                    }
                }
            }
        }
        return resultColsValues;
    }

    #getHtmlInputRadioColumnValues() {
        // returns JSON key/value pairs for all radio input elements that have a data-column attribute
        // handled separately to normal inputs and radio inputs are grouped and only one in each group can be checked
        let resultColsValues = {};
        let jqRadioInputs = $('#' + this.Id).find('input[type=radio]:checked');
        for (let i = 0; i < jqRadioInputs.length; i++) {
            let jqInput = $(jqRadioInputs[i]);
            let colName = jqInput.data('column');
            if (!colName) {
                colName = jqInput.data('name');
            }
            if (!colName) {
                console.warn('Radio input with no data-column or data-name attribute:', jqInput);
            } else {
                if (jqRadioInputs[i].checked == true) {
                    let inputValue = jqInput.val();
                    if ((inputValue) && (inputValue.length > 0)) {
                        console.log('radio', jqInput.attr("checked"), jqInput);
                        if (inputValue.toLowerCase() == 'true') {
                            resultColsValues[colName] = true;
                        } else if (inputValue.toLowerCase() == 'false') {
                            resultColsValues[colName] = false;
                        } else {
                            resultColsValues[colName] = inputValue;
                        }
                    }
                }
            }
        }
        return resultColsValues;
    }

    #getHtmlSelectColumnValues() {
        // returns JSON key/value pairs for all select elements that have a data-column attribute
        let resultColsValues = {};
        let jqSelects = $('#' + this.Id).find('select');
        for (let i = 0; i < jqSelects.length; i++) {
            let jqSelect = $(jqSelects[i]);
            let colName = jqSelect.data('column');
            if (!colName) {
                colName = jqSelect.data('name');
            }
            if (!colName) {
                console.warn('Select with no data-column or data-name attribute:', jqSelect);
            } else {
                let selectValue = jqSelect.find(":selected").val();
                if ((selectValue) && (selectValue.length > 0)) resultColsValues[colName] = selectValue;
            }
        }
        return resultColsValues;
    }

    #getHtmlTextareaColumnValues() {
        // returns JSON key/value pairs for all textarea elements that have a data-column attribute
        let resultColsValues = {};
        let jqTextAreas = $('#' + this.Id).find('textarea');
        for (let i = 0; i < jqTextAreas.length; i++) {
            let jqTA = $(jqTextAreas[i]);
            let colName = jqTA.data('column');
            if (!colName) {
                colName = jqTA.data('name');
            }
            if (!colName) {
                console.warn('TextArea with no data-column or data-name attribute:', jqTA);
            } else {
                let taValue = jqTA.val();
                if ((taValue) && (taValue.length > 0)) resultColsValues[colName] = taValue;
            }
        }
        return resultColsValues;
    }

}