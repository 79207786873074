import Overlay from "./Overlay";

export default class OverlayFromJson extends Overlay {

    constructor(whereSelectorString, optionalWhatJson, optionalHeight) {
        super(whereSelectorString, optionalWhatJson, optionalHeight);
    }

    // input options example {body: [{ name: 'locationName', label: 'Location Name', placeholder: 'Enter a name', type: "text" }], buttons: []}
    Render(optionsJson, callback) {
        let { title, bodyHtml, buttonsHtml } = this.#translateOptionsToHtml(optionsJson);
        super.Render(title, bodyHtml, buttonsHtml, callback);
    }

    // internals

    /*
        let jsonOptions = {
        title: "name", 
        body: [
            { name: 'locationName', label: 'Location Name', placeholder: 'Enter location name', type: "text" }
        ],
        buttons: [
            {action: "ok", title: "OK"}
        ]
    };
    */
    #translateOptionsToHtml(optionsJson) {
        let title = '';
        let body = '';
        let buttons = '';
        if ('body' in optionsJson) {
            body = this.#translateInputOptionsToHtml(optionsJson.body);
        } else {
            console.warn('OverlayFromJson - WARNING - No body attribute supplied in optionsJson');
        }
        if ('buttons' in optionsJson) {
            buttons = this.#translateButtonOptionsToHtml(optionsJson.buttons);
        } else {
            buttons = '<button class="clickableElement  overlayButton bg-green-300 hover:border-yellow-300 text-purple-950 text-2xl font-gotham border-solid border-green-300 rounded-lg">OK</button>';
        }
        return { title: (optionsJson.title || 'Untitled'), bodyHtml: body, buttonsHtml: buttons };
    }

    #translateInputOptionsToHtml(inputOptionsArray) {
        let body = '';
        for (let i = 0; i < inputOptionsArray.length; i++) {
            body += '<div class="p-2 m-1 md:m-2">';
            let el = inputOptionsArray[i];
            if ('beforeHtml' in el) body += el.beforeHtml;
            let value = '';
            if (('value' in el) && (el.value)) value = el.value;
            let readonlyHtml = '';
            if (('readonly' in el) && (el.readonly)) readonlyHtml = ' readonly';
            switch (el.type) {
                case 'message':
                    body += '<div class="p-1 md:p-2 text-yellow-300">' + value + '</div>';
                case 'hidden':
                    body += '<input type=hidden data-name="' + el.name + '" placeholder="' + el.placeholder + '" value="' + value + '" ' + readonlyHtml + ' />';
                    break;
                case 'text':
                    body += '<input class="w-full md:w-1/2 md:input p-2" type=text data-name="' + el.name + '" placeholder="' + el.placeholder + '" value="' + value + '" ' + readonlyHtml + ' />';
                    break;
                case 'password':
                    body += '<input class="w-full md:w-1/2 md:input p-2" type=password data-name="' + el.name + '" placeholder="' + el.placeholder + '" value="' + value + '" ' + readonlyHtml + ' />';
                    break;
                case 'email':
                    body += '<input class="w-full md:w-1/2 md:input p-2" type=email data-name="' + el.name + '" placeholder="' + el.placeholder + '" value="' + value + '" ' + readonlyHtml + ' />';
                    break;
                case 'time':
                    body += '<input class="w-full md:w-1/2 md:input p-2" type=time data-name="' + el.name + '" placeholder="' + el.placeholder + '" value="' + value + '" ' + readonlyHtml + ' />';
                    break;
                case 'date':
                    body += '<input class="w-full md:w-1/2 md:input p-2" type=date data-name="' + el.name + '" placeholder="' + el.placeholder + '" value="' + value + '" ' + readonlyHtml + ' />';
                    break;
                case 'datetime':
                    body += '<input class="w-full md:w-1/2 md:input p-2" type="datetime-local" data-name="' + el.name + '" placeholder="' + el.placeholder + '" value="' + value + '" ' + readonlyHtml + ' />';
                    break;
                case 'number':
                    if (('narrow' in el) && (el.narrow)) {
                        body += '<input class="w-full md:w-1/2 md:input p-2" type=number data-name="' + el.name + '" placeholder="' + el.placeholder + '" value="' + value + '" ' + readonlyHtml + ' style="width:70px;" />';
                    } else {
                        body += '<input class="w-full md:w-1/2 md:input p-2" type=number data-name="' + el.name + '" placeholder="' + el.placeholder + '" value="' + value + '" ' + readonlyHtml + ' />';
                    }
                    break;
                case 'twoNumbers':
                        if (('narrow' in el) && (el.narrow)) {
                            body += '<input class="w-full md:w-1/2 md:input p-2" type=number data-name="' + el.name + '1" placeholder="' + el.placeholder + '" value="' + value + '" ' + readonlyHtml + ' style="width:70px;" />';
                            if ('separator' in el) body += el.separator;
                            body += '<input class="w-full md:w-1/2 md:input p-2" type=number data-name="' + el.name + '2" placeholder="' + el.placeholder + '" value="' + value + '" ' + readonlyHtml + ' style="width:70px;" />';
                        } else {
                            body += '<input class="w-full md:w-1/2 md:input p-2" type=number data-name="' + el.name + '1" placeholder="' + el.placeholder + '" value="' + value + '" ' + readonlyHtml + ' />';
                            if ('separator' in el) body += el.separator;
                            body += '<input class="w-full md:w-1/2 md:input p-2" type=number data-name="' + el.name + '2" placeholder="' + el.placeholder + '" value="' + value + '" ' + readonlyHtml + ' />';
                        }
                        break;                    
                case 'tel':
                    body += '<input class="w-full md:w-1/2 md:input p-2" type=tel data-name="' + el.name + '" placeholder="' + el.placeholder + '" value="' + value + '" ' + readonlyHtml + ' />';
                    break;
                case 'textarea':
                    let rows = 5;
                    if ('rows' in el) rows = el.rows;
                    body += '<textarea data-name="' + el.name + '" rows="' + rows + '" placeholder="' + el.placeholder + '" ' + readonlyHtml + '>' + value + '</textarea>';
                    break;
                case 'select':
                    //console.log('Select Json: ', el)
                    body += '<select data-name="' + el.name + '" placeholder="' + el.placeholder + '" value="' + value + '" ' + readonlyHtml + '>';
                    if (('options' in el) || (el.options.length == 0)) {
                        let optionElType = typeof el.options[0];
                        el.options.forEach((optionEl) => {
                            let selectedFlag = '';
                            if (optionElType == 'object') {
                                if (optionEl.uuidv4 == el.value) selectedFlag = ' selected';
                                body += '<option value="' + optionEl.uuidv4 + '"' + selectedFlag + '>' + optionEl.title + '</option>'
                            } else {
                                if (optionEl == el.value) selectedFlag = ' selected';
                                body += '<option value="' + optionEl + '"' + selectedFlag + '>' + optionEl + '</option>'
                            }
                        });
                    } else {
                        console.warn('OverlayFromJson - select dropdown "options" attribute is missing or zero length');
                    }
                    body += '</select>';
                    break;
                case 'map':
                    body += '<div id="OsmMap">';
                    body += '</div>';
                    break;
                default:
                    console.log('Unrecognised overlay input option: ', el);
            }
            if ('afterHtml' in el) body += el.afterHtml;
            body += '</div>';
        }
        return body;
    }

    #translateButtonOptionsToHtml(buttonOptionsArray) {
        let buttonsHtml = '';
        if ((typeof buttonOptionsArray === 'undefined') || (!buttonOptionsArray)) {
            buttonsHtml = '<button class="clickableElement btn overlayButton m-2">OK</button>';
        } else {
            for (let i = 0; i < buttonOptionsArray.length; i++) {
                let buttonOptions = buttonOptionsArray[i];
                buttonsHtml += '<button class="clickableElement btn overlayButton m-2" data-action="' + buttonOptions.action + '">' + buttonOptions.title + '</button>';
            }
        }
        return buttonsHtml;
    }

}
