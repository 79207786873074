/**
 * Static class for the managing messages that are added to conversation history
 * @author Glen Birkbeck
 */
export default class HistoryController {

    static #userName = 'You';
    static #assistantName = 'Asguard'
    static #historyInitialMsg = 'Your conversations will appear here';

    static SetUserName (userName) {
        this.#userName = userName;
    }

    static AddUserMessage(msg) {
        const htmlStart = '<p class="text-gray-700 dark:text-gray-300">' + this.#userName + ': ';
        const htmlEnd = '</p>';
        const html = htmlStart + msg + htmlEnd;
        this.#addToHistory(html);
    }

    static AddAsguardResponse(msg) {
        const htmlStart = '<p class="text-orange-900 dark:text-orange-200">' + this.#assistantName + ': ';
        const htmlEnd = '</p>';
        const html = htmlStart + msg + htmlEnd;
        this.#addToHistory(html);
    }

    static AddErrorMessage(msg) {
        const htmlStart = '<p class="text-red-600 dark:text-red-400">Error: ';
        const htmlEnd = '</p>';
        const html = htmlStart + msg + htmlEnd;
        this.#addToHistory(html);
    }

    // internals

    static #addToHistory(html) {
        let jqHistoryBlock = $('#HistoryBlock');
        if (jqHistoryBlock.html() == this.#historyInitialMsg) {
            jqHistoryBlock.html(html);
        } else {
            jqHistoryBlock.append(html);
        }
    }

}