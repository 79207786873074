import OverlayFromJson from "../Overlay/OverlayFromJson.js";
import Validator from "../../utils/Validator.js";
import Auth from "../../utils/Auth.js";
/**
 * Static class for the main web application entry point
 * @author Glen Birkbeck
 */
export default class LoginForm {
    static #callback;

    static Show(callbackCB) {
        this.#callback = callbackCB; // store this for after we have processed the login
        let overlayOptions = {
            title: "Login",
            body: [
                {
                    name: 'email',
                    label: 'Email',
                    placeholder: 'your@email.com',
                    type: "email"
                },
                {
                    name: 'password',
                    label: 'Password',
                    placeholder: 'Enter a password',
                    type: "password"
                }
            ],
            buttons: [
                { action: "ok", title: "Login" }
            ]
        }
        let uiOverlay = new OverlayFromJson();
        uiOverlay.Render(overlayOptions, this.#handleFormSubmission);
    }

    // Uses arrow function form to retain "this"
    static #handleFormSubmission = async (jqButton, uiOverlay) => {
        let overlayResultsJson = uiOverlay.GetAllInputs()
        //console.log('overlayResultsJson: ', overlayResultsJson)
        let email = overlayResultsJson.email;
        let pass = overlayResultsJson.password;
        if (!Validator.Email(email)) {
            alert('Invalid email');
            console.warn('Invalid email');
        } else {
            let loginResultJson = await Auth.Login(email, pass);
            //console.log('loginResultJson: ',loginResultJson);
            if (loginResultJson.err) {
                console.log('Login failed');
            } else {
                uiOverlay.Hide();
                //console.log('overlayResultsJson: ', overlayResultsJson);
                this.#callback(true);
            }
        }
    }
}
