import Tokeniser from "../../utils.delete/Tokeniser.js";
import Snackbar from './Snackbar/Snackbar.js';

export default class UiBase {
    /**
     * @class
     * @name UiBase
     */

    // every class that inherits from UiBase increments this in constructor, meaning each gets a unique counter (within the page) for the Id
    static idCounter = 0;

    #FLASH_CLASS = 'flashMessages';
    #FLASH_CLOSE_DELAY = 5000; // 5 seconds
    #flashMsgTimeout = null;

    #ID_PREFIX = 'UiBase_';
    Id = 'UiBase_0'; // this gets set in the constructor so each instance has a unique Id

    #whereSelectorString = ''; // HTML DOM query selector for jquery
    #whatJson = {}; // attributes to replace tokens with

    // html template we will do tokenisation on and use as the main UI: 
    //   {this.foo} gets replaced with this object public attribs, e.g. foo in this example
    //   Use {this.Id} for the outer element, e.g. <div id="{this.Id}">Content</div>
    //   {what.bar} is replaced with whatJson.bar
    //   whatJsonMappings.bar is used if there is any mapping needed for .bar attribute
    #howTemplateHtml = '';

    // optional what attributes that are mapped, from perhaps an int to text, in an enum like fashion
    // see Template above and /js/ui/panels/User/AccountDetails/Mappings.js for example
    #whatJsonMappings = {};

    #parentUi = null;

    /**
     * Create a UiBase object. Generates a unique ID for the class, handles HTML subsitution, rendering and hiding.
     * @param {String} whereSelectorString - the target DIV ID
     * @param {JSON} whatJson - JSON name/value pairs for what.name in howTemplateHtml replacements
     * @param {String} howTemplateHtml - HTML template
     * @param {JSON} optionalWhatJsonMappings  - Optional mappings of WhatJson values into strings
     */
    constructor(whereSelectorString, whatJson, howTemplateHtml, optionalWhatJsonMappings) {
        this.Id = this.#ID_PREFIX + UiBase.idCounter;
        UiBase.idCounter++;
        this.#whereSelectorString = whereSelectorString || '';
        this.#whatJson = whatJson || {};
        if ( whatJson && ('id' in whatJson === false)) whatJson.id = '';
        if ( whatJson && ('style' in whatJson === false)) whatJson.style = '';
        this.#whatJsonMappings = optionalWhatJsonMappings || {};
        this.#howTemplateHtml = howTemplateHtml || '';
        
        //console.log('UiBase ID: ' + this.Id)
    }

    SetParentUi = (parentUi) => {
        this.#parentUi = parentUi;
    }

    ConfigureParentEventHandlers = () => {
        if ( (typeof this.#parentUi !== 'undefined') ) { //&& (this.#parentUi.hasOwnProperty('ConfigureEventHandlers'))) {
            this.#parentUi.ConfigureEventHandlers();
        }
    }

    /**
     * Disables the button, calls the function, then enables the button on successful completion. Function is treated as async
     * @param {object} jqButton - a jquery button that has been pressed
     * @param {function} asyncActionFunc - the function to call and await to return
     */
    async ProcessButtonAsyncAction(jqButton, asyncActionFunc, ...otherArgs) {
        $('section.' + this.#FLASH_CLASS).hide();
        jqButton.prop('disabled', true);
        jqButton.addClass('disabledButton');
        let buttonText = jqButton.html();
        jqButton.html('<button-loading></button-loading>');
        //try {
            await asyncActionFunc(jqButton, ...otherArgs);
        //} catch (err) {
        //    console.warn('Error occurred on button click: ', err);
        //}
        jqButton.prop('disabled', false);
        jqButton.removeClass('disabledButton');
        jqButton.html(buttonText);
    }

    ShowSnackbar(msg) {
        Snackbar.Show(msg);
    }
    /**
     * Shows a flash message at the top of the page. If timeout is specified, the message will be autoremoved after the timeout
     * @param {string} msg - the message to display
     * @param {number} optionalTimeoutDelayMs - optional timeout in milliseconds
     */
    ShowFlashMessage(msg, optionalTimeoutDelayMs) {
        let timeoutDelayMs = optionalTimeoutDelayMs || this.#FLASH_CLOSE_DELAY;
        if (this.#flashMsgTimeout) {
            clearTimeout(this.#flashMsgTimeout);
            this.#flashMsgTimeout = null;
        }
        $('section.' + this.#FLASH_CLASS).html(msg);
        $('section.' + this.#FLASH_CLASS).fadeIn();
        if (timeoutDelayMs != 0) {
            this.#flashMsgTimeout = setTimeout(() => {
                $('section.' + this.#FLASH_CLASS).slideUp();
            }, timeoutDelayMs);
        }
    }

    /** Renders the UI element using information it has already been provided with (template, whatJson and whatJsonMappings) */
    Render() {
        let html = this.#generateHtml(this.#howTemplateHtml, this.#whatJson, this.#whatJsonMappings);
        $(this.#whereSelectorString).html(html);
        $(this.#whereSelectorString).show();
    }

    /**
     * Generates the HTML but leaves it up to the caller to inject into the HTML DOM
     * @returns string - html to inject
     */
    GenerateHtml() {
        return this.#generateHtml(this.#howTemplateHtml, this.#whatJson, this.#whatJsonMappings);
    }

    /** Hides the UI element */
    Hide() {
        $(this.#whereSelectorString).hide();
    }

    /** Slides the UI element up to hide it */
    SlideUp() {
        $(this.#whereSelectorString).slideUp();
    }

    UiAsJquery() {
        return $(this.#whereSelectorString);
    }

    /**
     * Set up a click handler, wrapped in a method to change the button to a busy indicator so an async action can be performed
     * @param {string} divId - div id (without a leading #) for click handler
     * @param {function} funcToCall the function to call when clicked
    */
    SetupButtonClickHandlerByDivId(divId, asyncFuncToCall) {
        let self = this;
        $('#' + divId).off('click');
        $('#' + divId).on('click', async function (evt) {
            await self.ProcessButtonAsyncAction($(this), asyncFuncToCall);
        });

    }
    // html helpers, primarily used in misc/DataTable/RecordOverlay...

    HtmlFormTextInput(elementId, value, dataColumnName, placeHolder, isDisabled) {
        let disabled = '';
        if (isDisabled) disabled = ' disabled';
        let html = '<input type=text ';
        if (elementId) html += 'id="' + elementId + '" ';
        if (dataColumnName) html += 'data-column="' + dataColumnName + '" ';
        if (placeHolder) html += 'placeholder="' + placeHolder + '" ';
        if (value) html += 'value="' + value + '" ';
        html += disabled + '>';
        return html;
    }

    HtmlFormDateTimeLocalInput(elementId, value, dataColumnName, isDisabled) {
        let disabled = '';
        if (isDisabled) disabled = ' disabled';
        let html = '<input type=datetime-local ';
        if (elementId) html += 'id="' + elementId + '" ';
        if (dataColumnName) html += 'data-column="' + dataColumnName + '" ';
        if (value) html += 'value="' + value + '" ';
        html += disabled + '>';
        return html;
    }

    HtmlFormTextArea(elementId, value, rows, cols, dataColumnName, isDisabled) {
        let disabled = '';
        if (isDisabled) disabled = ' disabled';
        let html = '<textarea ';
        if (elementId) html += 'id="' + elementId + '" ';
        if (dataColumnName) html += 'data-column="' + dataColumnName + '" ';
        if (rows) html += 'rows=' + rows + ' ';
        if (cols) html += 'cols=' + cols + ' ';
        html += disabled + '>';
        html += value;
        html += '</textarea>';
        return html;
    }

    HtmlFormSelectDropdown(elementId, mappingsForField, fieldValue, dataColumnName, isDisabled) {
        let disabled = '';
        if (isDisabled) disabled = ' disabled';
        let html = '<select ';
        if (elementId) html += 'id="' + elementId + '" ';
        if (dataColumnName) html += 'data-column="' + dataColumnName + '" ';
        html += disabled + '>';
        let keys = Object.keys(mappingsForField);
        keys.forEach((key) => {
            let htmlSelected = '';
            if (key + '' == fieldValue + '') htmlSelected = 'selected';
            html += '<option value="' + key + '" ' + htmlSelected + '>' + mappingsForField[key] + '</option>'
        });
        html += '</select>';
        return html;
    }

    HtmlFormTrueFalseRadioButtons(parentElementId, colName, fieldValue, isDisabled) {
        //console.log('UiBase.HtmlFormTrueFalseRadioButtons() ', parentElementId, colName, fieldValue, isDisabled);
        let trueChecked = '';
        let falseChecked = '';
        if (fieldValue) trueChecked = 'checked';
        else falseChecked = 'checked';
        let disabled = '';
        if (isDisabled) disabled = ' disabled';
        let html = '<span>';
        html += '<label for="' + parentElementId + '_' + colName + '_true">True</label>';
        html += '<input type="radio" id="' + parentElementId + '_' + colName + '_true" name="' + parentElementId + '_' + colName + '" data-column="' + colName + '" value="True" ' + disabled + ' ' + trueChecked +'>';
        html += '<label for="' + parentElementId + '_' + colName + '_false">False</label>';
        html += '<input type="radio" id="' + parentElementId + '_' + colName + '_false" name="' + parentElementId + '_' + colName + '" data-column="' + colName + '" value="False" ' + disabled + ' ' + falseChecked +'>';
        html += '</span>';
        return html;
    }

    HtmlButton(elementId, dataAction, dataValue, label, isDisabled) {
        let className = '';
        if (!isDisabled) className = 'clickableElement';
        let html = '<button ';
        if (elementId) html += 'id="' + elementId + '" ';
        html += 'class="' + className + '" ';
        if (dataAction) html += 'data-action="' + dataAction + '" ';
        if (dataValue) html += 'data-value="' + dataValue + '" ';
        html += '>' + label + '</button>';
        return html;
    }

    #generateHtml(template, what, mappings) {
        return Tokeniser.DoTokenReplacement(this, template, what, mappings);
    }
}