import UiBase from '../UiBase.js';
import Template from './Template.html';
import Footer from "../Footer/Footer.js";
import ProfileForm from "../ProfileForm/ProfileForm.js";
import ChatPage from "../ChatPage/ChatPage.js";
import AdminPage  from "../AdminPage/AdminPage.js";

export default class MainPage extends UiBase {
    static Init = async () => {
        $('#UserNotLoggedIn').hide();
        $('#UserLoggedIn').show();
        $('#MobileNav').hide();
        $('#MobileUserNotLoggedIn').hide();
        if ($(window).width() <= 640) $('#MobileUserLoggedIn').show();
        let jqContent = $('#Content');
        jqContent.html(Template);
        if ($('footer').length > 0) {
            let uiFooter = new Footer();
            uiFooter.Render();
        }
        this.#configureEventHandlers();
    }

    static async ShowProfile() {
        await ProfileForm.Show(_profileEditingFinished);
        function _profileEditingFinished(isSuccess) {
            console.log('TODO: Update page _profileEditingFinished() ', isSuccess);
        }
    }

    static async ShowChatPage() {
        await ChatPage.Init();
        ChatPage.Show();
    }

    static async ShowAdminPage() {
        let admPage = new AdminPage();
        admPage.Init();
    }

    // internals

    static #configureEventHandlers = () => {
        let self = this;
        $('#ProfileBtn').off('click');
        $('#ProfileBtn').on('click', async function () {
            await self.ShowProfile();
        });
        $('#MobileProfileBtn').off('click');
        $('#MobileProfileBtn').on('click', async function () {
            await self.ShowProfile();
        });
        $('#MobileNavCloseBtn').off('click');
        $('#MobileNavCloseBtn').on('click', function () {
            $('#MobileNav').hide();
        });
        $('#MobileNav2CloseBtn').off('click');
        $('#MobileNav2CloseBtn').on('click', function () {
            $('#MobileNav').hide();
        });
        // 
        $('#ShowChatPage').off('click');
        $('#ShowChatPage').on('click', async function () {
            await self.ShowChatPage();
        });
        $('#showAdminPage').off('click');
        $('#ShowAdminPage').on('click', async function () {
            await self.ShowAdminPage();
        });
    }
}