import Api from './Api.js';
/**
 * Static class for the communicating with API endpoints
 * @author Glen Birkbeck
 */
export default class LlmController {

    static #uris = {
        'SpeechToText': '/llmapi/TranscribeAudio',
        'SendMessageToAI': '/llmapi/SendChatMessageAndGetResponse',
        'TextToSpeech': '/llmapi/TextToSpeech'
    };

    static async ConvertSpeechBlobsToText(recordedBlobs) {
        let resultsJson = {};
        const audioBlob = new Blob(recordedBlobs, { type: 'audio/wav' });
        console.log(`Audio Blob size: ${audioBlob.size}`);
        const formData = new FormData();
        formData.append('audio', audioBlob);
        formData.append('name', 'audio.wav');
        let headers = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
        try {
            let response = await Api.Post(this.#uris.SpeechToText, formData, headers);
            console.log('API STT success:');
            resultsJson.msg = response.data.text;
        } catch (e) {

            resultsJson.error = 'Error converting speech to text';
            console.log('Error in TranscribeAudio API:');
            console.log(e);
        }
        return resultsJson;
    }

    static async SendMsgToAi(userMsg) {
        let resultsJson = {};
        let apiResultsJson = {};
        try {
            apiResultsJson = await Api.Post(this.#uris.SendMessageToAI, {
                message: userMsg
            });
        }
        catch (err) {
            apiResultsJson = err;
        }
        if (apiResultsJson.status == 200) {
            if (!apiResultsJson.data.err) {
                resultsJson.msg = apiResultsJson.data.response;
            } else {
                resultsJson.error = apiResultsJson.data.err;
            }
        } else {
            resultsJson = 'Server issue (HTTP ' + apiResultsJson.status + ') ' + apiResultsJson.statusText;
        }
        return resultsJson;
    }

    static async ConvertResponseToMp3Uri(responseText) {
        let apiResultsJson = null;
        try {
            apiResultsJson = await Api.Post(this.#uris.TextToSpeech, {
                message: responseText
            });
        }
        catch (err) {
            apiResultsJson = err;
        }
        console.log('Received for mp3: ', apiResultsJson);
        return apiResultsJson.data;
    }

}