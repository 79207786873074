import HomePage from "./ui/HomePage/HomePage.js";
import MainPage from "./ui/MainPage/MainPage.js";
import CurrentUser from "./data/LocalStorage/CurrentUser.js";
import Preferences from "./data/Preferences.js";
import LoginForm from "./ui/LoginForm/LoginForm.js";
import RegisterForm from "./ui/RegisterForm/RegisterForm.js";

/**
 * Static class for the main web application entry point
 * @author Glen Birkbeck
 */
export class WebApp {
    static isInitialised = false;

    static Init = async () => {
        //await Store.Initialise();
        //await PageHandler.Init();
        if (!this.isInitialised) {
            await Preferences.configure({ group: "Asguard" });
            await CurrentUser.Init();
            let userJson = await CurrentUser.Details();
            if ((userJson.id == 0) || (userJson.email == '') || (userJson.clientKey == '') || (userJson.refreshToken == '')) {
                await HomePage.Init();
            } else {
                await MainPage.Init();
            }
            this.isInitialised = true;
        }
        $('#MobileNav').hide();
        await this.#configureEvents()
        await this.#configureClickHandlers()
    }

    // internals

    static async #configureEvents() {
        let self = this;
        $(window).resize(async function () {
            if ($(this).width() > 640) {
                $('#MobileNav').hide();
                $('#NormalNav').show();
            } else {
                $('#MobileNav').show();
                $('#NormalNav').hide();
            }
            await self.#configureClickHandlers()
        });
    }
    /**
     * Internal setup of all click handlers
     */
    static #configureClickHandlers() {
        let self = this;
        $(document).ready(function () { // handle back button gracefully to rerender page
            $(window).on("popstate", function (e) {
                location.reload();
            });
        });
        if ($(window).width() <= 640) {
            $('#NormalNav').hide();
            $('#MobileOpenButton').on('click', function () {
                $('#MobileNav').show();
            });
        } else {
            $('#MobileNav').hide();
        }

        $('#LoginBtn').off('click');
        $('#LoginBtn').on('click', function () {
            self.ShowLoginOverlay();
        });
        $('#MobileLoginBtn').off('click');
        $('#MobileLoginBtn').on('click', function () {
            $('#MobileNav').hide();
            self.ShowLoginOverlay();
        });
        $('#RegisterBtn').off('click');
        $('#RegisterBtn').on('click', function () {
            self.ShowRegisterOverlay();
        });
        $('#MobileRegisterBtn').off('click');
        $('#MobileRegisterBtn').on('click', function () {
            $('#MobileNav').hide();
            self.ShowRegisterOverlay();
        });
        $('#MobileNavCloseBtn').off('click');
        $('#MobileNavCloseBtn').on('click', function () {
            $('#MobileNav').hide();
        });
    }

    static ShowLoginOverlay = () => {
        let self = this;
        LoginForm.Show(_loginComplete)
        async function _loginComplete(isSuccess) {
            console.log('TODO: Update page _loginComplete() ', isSuccess);
            if (isSuccess) {
                await MainPage.Init();
                self.#configureClickHandlers();
            }
        }
    }

    static ShowRegisterOverlay = async () => {
        let self = this;
        RegisterForm.Show(_registerComplete)
        async function _registerComplete(isSuccess) {
            console.log('TODO: Update page _registerComplete() ', isSuccess);
            if (isSuccess) {
                await MainPage.Init();
                self.#configureClickHandlers();
            }
        }
    }
}