export default class Validator {

    static Email(email) {
        let isValidEmail = false;
        if ((email) && (email.length > 0)) {
            //var reg = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
            var reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            var result = reg.test(email);
            if (result) {
                isValidEmail = true;
            }
        }
        return isValidEmail;
    }
}