import TableBase from "./TableBase.js";
import DefaultConfig from '../Defaults/Config.json';

// For capacitor we import Preferences from their library, but for a PWA we use our own wrapper class for Preferences
import Preferences from "../Preferences.js";

export default class ActivitySharing extends TableBase {
    static TABLE_NAME = 'Config';

    //
    // TODO: Change this and User to inherit from a new class ItemBase
    // Then change the way individual attributes are saved
    //
    
    static ENV = { 
        PROD: 1,        // api uri needs prefixing with https://asguard.app
        DEV: 2,         // api uri needs prefixing with http://localhost:4002
        DEV_EMULATOR: 3,// api uri needs prefixing with http://192.168.1.188:4002 
    };   

    static #cachedJson = DefaultConfig;

    static Env = async (optionalNewEnv) => {
        let resultEnv = this.ENV.PROD; // default
        optionalNewEnv = optionalNewEnv || null;
        await this.GetAll();
        if (optionalNewEnv) {
            this.#cachedJson.env = optionalNewEnv;
            await this.SaveAll(this.#cachedJson);
            resultEnv = optionalNewEnv;
        } else {
            if ('env' in this.#cachedJson === false) {
                this.#cachedJson.env = resultEnv;
                await this.SaveAll(this.#cachedJson);
            } else {
                resultEnv = this.#cachedJson.env; // its already set - this should be the norm
            }
        }
        return resultEnv;
    }

    /*static LastSyncDt = async (optionalLastSyncDt) => {
        optionalLastSyncDt = optionalLastSyncDt || 0;
        if ((this.#cachedJson.lastSyncDt == '') || (this.#cachedJson.lastSyncDt == 0)) {
            await this.GetAll();
        }
        return this.#cachedJson.lastSyncDt;
    }*/

    static Details = async () => {
        let { value } = await Preferences.get({ key: this.TABLE_NAME });
        if ((typeof value === 'undefined') || (!value)) { // if we have no data
            this.#cachedJson.uuidv4 = this.NewUuidv4();
            await this.SaveAll(this.#cachedJson);
        } else {
            this.#cachedJson = JSON.parse(value);
        }
        return this.#cachedJson;
    }
}