import LlmController from './LlmController.js';
import ChatPage from '../ui/ChatPage/ChatPage.js';
/**
 * Static class for recording audio 
 * @author Glen Birkbeck
 */
export default class AudioRecorder {
    static #recordBtn = document.getElementById('RecordAudioBtn');
    //static #transcriptTextarea = document.getElementById('transcript');

    static recordedBlobs
    static mediaStream;
    static mediaRecorder;

    static Init() {
        // Add event listeners to the record button
        this.#setupEventHandlers();
    }

    static #setupEventHandlers() {
        let self = this;
        this.#recordBtn = document.getElementById('RecordAudioBtn');
        this.#recordBtn.addEventListener('click', async (event) => {
            if (self.mediaRecorder && (self.mediaRecorder.state != 'inactive')) {
                this.#stopRecording();
            } else {
                await this.#startRecording();
            }
        });
    }

    // Function to start recording audio
    static #startRecording() {
        let self = this;
        this.recordedBlobs = [];
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                self.mediaStream = stream;
                self.mediaRecorder = new MediaRecorder(stream);
                this.#recordBtn.textContent = 'Stop Recording';
                this.#recordBtn.classList.add('dark:bg-red-600');
                this.#recordBtn.classList.remove('dark:bg-green-600');
                self.mediaRecorder.start();
                // event handler gets the audio in one or more chunks/blobs
                self.mediaRecorder.ondataavailable = function (event) {
                    if (event.data.size > 0) {
                        self.recordedBlobs.push(event.data);
                        console.log('ondataavailable pushed: ', self.recordedBlobs);
                    }
                };
                self.mediaRecorder.onstop = async (e) => {
                    console.log("Audio recorder stopped");
                    let responseJson = await LlmController.ConvertSpeechBlobsToText(self.recordedBlobs);
                    console.log('responseJson: ', responseJson);
                    if (!responseJson.msg) {
                        ChatPage.ProcessError('Conversion of speech to text failed');
                    } else if (('error' in responseJson) && (responseJson.error)) {
                        ChatPage.ProcessError(responseJson);
                    } else {
                        ChatPage.ProcessUserMsg(responseJson.msg);
                    }
                };
            })
            .catch(error => console.error('Error starting recording:', error));
    }

    // Function to stop recording. the onstop handler will send the audio data to the API
    static async #stopRecording() {
        this.mediaRecorder.stop();
        console.log(this.mediaRecorder);
        this.#recordBtn.textContent = 'Talk some more';
        this.#recordBtn.classList.add('dark:bg-green-600');
        this.#recordBtn.classList.remove('dark:bg-red-600');
    }

}