import TemplateHtml from './Template.html';

export default class Snackbar {
    static #DIV_ID = 'Snackbar';
    static #SHOW_CLASS = 'snackbarShow';
    static #DEFAULT_DURATION_MS = 3000;
    static #SNACKBAR_COUNT = 1;
    static #SNACKBAR_MSGS = [];

    static Show = (msg, optionalDurationMs) => {
        console.log('Snackbar: ' + msg);
        let jqEl = this.#getSnackbarDiv();
        jqEl.css('visibility','visible');
        jqEl.show(0);
        let self = this;
        setTimeout(() => {
            self.#addSnackBarMessage(jqEl, msg, optionalDurationMs);

        },50);
    }

    static #addSnackBarMessage = (jqEl, msg, optionalDurationMs) => {
        let self = this;
        let durationMs = optionalDurationMs || this.#DEFAULT_DURATION_MS;
        let msgDivId = this.#DIV_ID + '__' + this.#SNACKBAR_COUNT;
        jqEl.prepend('<div id="' + msgDivId + '" class="' + this.#SHOW_CLASS + '">' + msg + '</div>');
        //console.log(jqEl.html());
        this.#SNACKBAR_MSGS.push(msg);
        setTimeout(() => {
            //console.log('msgDivId: ',msgDivId)
            let jqMsgEl = $('#' + msgDivId);
            jqMsgEl.removeClass(this.#SHOW_CLASS);
            self.#SNACKBAR_MSGS.pop();
            if (self.#SNACKBAR_MSGS.length==0) {
                let jqEl = $('#' + this.#DIV_ID);
                jqEl.html('');
            }
        }, durationMs);
        this.#SNACKBAR_COUNT++;
    }

    static #getSnackbarDiv = () => {
        let jqEl = $('#' + this.#DIV_ID);
        if (jqEl.length == 0) { // append to dom if it doesnt exist
            $('body').append(TemplateHtml);
            jqEl = $('#' + this.#DIV_ID);
        }
        return jqEl;
    }
}