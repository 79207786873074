import Users from '../../core/data/LocalDb/Users/Users.js';

/*

    05/09/2024 - This ought to be auto generated by the scaffolding process
*/

/*import Group from './Group.js';
import GroupMember from './GroupMember.js';
import Person from './Person.js';
import PersonRating from './PersonRating.js';
import PersonReview from './PersonReview.js';
import Rating from './Rating.js';
import Realm from './Realm.js';
import RolePersonRealm from './RolePersonRealm.js';
import Skill from './Skill.js';
import Category from './Category.js';
import SkillRating from './SkillRating.js';

import Measure from './Measure.js';
import PersonMeasurement from './PersonMeasurement.js';
import Session from './Session.js';
import SessionAttendee from './SessionAttendee.js';

import Invite from './Invite.js';
*/

import CircleOfTrust from './CircleOfTrust.js';
import ConversationHistory from './ConversationHistory.js';
import UserConfig from './UserConfig.js';
/* 
    Used by ./src/js/ui/misc/DataTable as it needs to be able to access the server APIs for all tables
*/

export default {
    "User": Users,
    "CircleOfTrust": CircleOfTrust,
    "ConversationHistory": ConversationHistory,
    "UserConfig": UserConfig
    /*
    "Group": Group,
    "GroupMember": GroupMember,
    "Person": Person,
    "PersonRating": PersonRating,
    "PersonReview": PersonReview,
    "Rating": Rating,
    "Realm": Realm,
    "RolePersonRealm": RolePersonRealm,
    "Skill": Skill,
    "Category": Category,
    "SkillRating": SkillRating,

    "Measure": Measure,
    "PersonMeasurement": PersonMeasurement,
    "Session": Session,
    "SessionAttendee": SessionAttendee,

    "Invite": Invite*/
}