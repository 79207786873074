import TableBase from "./TableBase.js";
import Config from './Config.js';
import DefaultUser from '../Defaults/User.json';

// For capacitor we import "Preferences", but for a PWA we just assign Preferences to be window.localStorage
//import { Preferences } from '@capacitor/preferences';
import Preferences from "../Preferences.js";

export default class User extends TableBase {
    static TABLE_NAME = 'User';
    static #isFirstRunCurrentSession = true;
    static #cachedJson = null;
    static #accessToken = '';

    static Init = async () => { // Init called by top level WebApp.js
        if (this.#isFirstRunCurrentSession) { // populate cache if we can
            let { value } = await Preferences.get({ key: this.TABLE_NAME });
            console.log('value: ', value)
            if ((typeof value === 'undefined') || (!value)) { // if we have no data, set default data
                this.#cachedJson = DefaultUser;
                this.#cachedJson.uuidv4 = this.NewUuidv4();
                await this.SaveAll(this.#cachedJson);
            } else { // otherwise cache what we have
                this.#cachedJson = JSON.parse(value);
            }
        }
        this.#isFirstRunCurrentSession = false;
        console.log(this.#cachedJson);
        return this.#cachedJson;
    }

    static AccessToken =  (userAccessToken) => {
        if (userAccessToken) {
            // store access token in memory only
            this.#accessToken = userAccessToken;
        }
        //console.log('Returned Access Token: ', this.#accessToken);
        return this.#accessToken;
    }

    static Details = async (userDetailsJson) => {
        if (userDetailsJson) {
            if ('accessToken' in userDetailsJson) { // only store access token in memory
                this.#accessToken = userDetailsJson.accessToken;
                //console.log('Cached Access Token: ', this.#accessToken);
                delete userDetailsJson.accessToken;
            }
            await User.SaveAll(userDetailsJson);
            console.log('Saved user details: ', userDetailsJson);
        }
        return this.#cachedJson;
    }

    static HasUserEverLoggedIn = async () => {
        if (('email' in this.#cachedJson) && (this.#cachedJson.email) && (this.#cachedJson.email.length > 0)) {
            return true;
        }
        else return false;
    }

    static FirstName = async () => {
        if (this.#cachedJson.firstName == '') {
            await this.Details();
        }
        return this.#cachedJson.firstName;
    }


    static ClientKey = async (newClientKey) => {
        if (newClientKey) {
            this.#cachedJson.clientKey = newClientKey;
            await this.SaveAll(this.#cachedJson);
        }
        return this.#cachedJson.clientKey;
    }

    static RefreshToken = async (newRefreshToken) => {
        if (newRefreshToken) {
            this.#cachedJson.refreshToken = newRefreshToken;
            await this.SaveAll(this.#cachedJson);
        }
        return this.#cachedJson.refreshToken;
    }

    static Uuidv4 = async () => {
        if (this.#cachedJson.uuidv4 == '') {
            await this.Details();
        }
        return this.#cachedJson.uuidv4;
    }

    static Logout = async () => {
        await Config.Clear();
        await this.Clear();
        document.cookie = 'jwt=; expires=Thu, 01-01-1970 00:00:00 GMT;';
        console.warn('User.Logout() Do we need to clear other tables? Have we warned the user of this?');
    }

    static Language = async () => {
        if (this.#cachedJson.language == '') {
            await this.Details();
            //this.SaveAll(this.#cachedJson);
        }
        return this.#cachedJson.language;
    }

    static Timezone = async () => {
        if (this.#cachedJson.timezone == '') {
            await this.Details();
            //this.SaveAll(this.#cachedJson);
        }
        return this.#cachedJson.timezone;
    }

    static SetOnlineAccountDetails = async (id, email, optionalType) => { // can this be retired now? Check this.
        this.#cachedJson = await this.Details();
        this.#cachedJson.id = id;
        this.#cachedJson.email = email;
        if (optionalType) this.#cachedJson.accountType = optionalType;
        else if (id == 0) this.#cachedJson.accountType = 'None';
        else this.#cachedJson.accountType = 'Basic';
        await this.SaveAll(this.#cachedJson);
    }

    static SessionTimezone = () => {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    static SetIsFirstEverRun = async (value) => {
        this.#cachedJson = await this.Details();
        this.#cachedJson.isFirstEverRun = value;
        await this.SaveAll(this.#cachedJson);
    }

    static AccessToken = (newToken) => {
        /* Store access token in memory only
        let result = '';
        await this.GetUserDetails();
        if ('accessToken' in this.#cachedDetails) result = this.#cachedDetails.accessToken;
        return result;*/
        if (newToken) this.#accessToken = newToken;
        return this.#accessToken;
    }

    static LastSyncDt = async (newSyncDt) => {
        if (newSyncDt) {
            console.log('Setting new sync date time');
            this.#cachedJson.lastSyncDt = newSyncDt;
            await this.SaveAll(this.#cachedJson);
        }
        console.log('>>>', this.#cachedJson);
        return this.#cachedJson.lastSyncDt;
    }

    static SaveAll = async (userJson) => {
        if (userJson) {
            userJson = Object.assign(this.#cachedJson, userJson);
        } else {
            userJson = DefaultUser;
        }
        this.#setDefaultIfNotPresent('language', navigator.language);
        this.#setDefaultIfNotPresent('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
        this.#setDefaultIfNotPresent('lastSyncDt', 0);
        this.#setDefaultIfNotPresent('accountType', 'None');
        if ((userJson.uuidv4 == '') && (this.#cachedJson.uuidv4 != '')) userJson.uuidv4 = this.#cachedJson.uuidv4;
        if (this.#cachedJson.uuidv4 != userJson.uuidv4) {
            console.warn('Attempt to change uuidv4 not allowed:', this.#cachedJson, userJson);
        } else {
            await Preferences.set({
                key: this.TABLE_NAME,
                value: JSON.stringify(userJson),
            });
            this.#cachedJson = userJson;
        }
    }

    static #setDefaultIfNotPresent = (attribName, attribValue) => {
        if ((attribName in this.#cachedJson === false) || (this.#cachedJson[attribName] == '')) {
            this.#cachedJson[attribName] = attribValue;
        }
    }
}