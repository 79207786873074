export default class Tokeniser {

    static DoTokenReplacement(objectClassInstance, templateHtml, whatJson, whatJsonMappings) {
        // search for anything wrapped in {...}, each attribute must be prefixed either with 'this.' or 'what.' which
        // then must match an object property (this) or a property in whatJson. The result must be simple string, int 
        // or whatever, date hanndling isnt currently built in
        let tokenResults = this.#extractTokensFromTemplate(templateHtml);
        //console.log('token results: ', tokenResults);
        let arrayForTokenisation = this.#buildTokenReplacements(objectClassInstance, tokenResults, whatJson, whatJsonMappings);
        //console.log('arrayForTokenisation: ', arrayForTokenisation);
        let resultsHtml = this.#doTokenReplacements(templateHtml, arrayForTokenisation);
        return resultsHtml;
    }

    static #extractTokensFromTemplate = (templateHtml) => {
        return templateHtml.match(/{([^}]+)}/g);
    }

    static #buildTokenReplacements = (objectClassInstance, tokenResults, whatJson, whatJsonMappings) => {
        let arrayForTokenisation = [];
        let tokensJson = {};
        let hasWhatMappings = (Object.keys(whatJsonMappings).length != 0); // if 0, we have no mappings so stuff gets rendered as is
        if (tokenResults) {
            tokenResults.forEach((token) => {
                if (token in tokensJson === false) {
                    tokensJson[token] = true;
                    //console.log('Processing token: ', token);
                    let strippedToken = token.replace('{', '').replace('}', '');
                    if ((strippedToken.length > 5) && (strippedToken.startsWith('this.'))) { // we expect the result under this[attrib]
                        let publicProperty = strippedToken.substring(5);
                        if (publicProperty in objectClassInstance) {
                            let publicValue = objectClassInstance[publicProperty];
                            arrayForTokenisation.push([token, publicValue]);
                        } else {
                            console.warn('Unmatched publicProperty in template: ' + publicProperty);
                        }
                    } else { // we expect the result in whatJson
                        let whatProperty = strippedToken.substring(5);
                        if (whatProperty in whatJson) {
                            let whatValue = whatJson[whatProperty];
                            if ((hasWhatMappings) && (whatProperty in whatJsonMappings)) {
                                whatValue = this.#buildTokenReplacementFromMapping(whatJsonMappings[whatProperty], whatValue)
                            }
                            arrayForTokenisation.push([token, whatValue]);
                        } else {
                            console.warn('Unmatched whatJson in template: ' + whatProperty);
                        }
                    }
                }
            });
        }
        return arrayForTokenisation;
    }

    static #buildTokenReplacementFromMapping = (mappingJson, value) => {
        let resultHtml = value;
        if (('values' in mappingJson) && (value in mappingJson.values)) {
            let mappingJsonForCurrentValue = mappingJson.values[value];
            let mappedValue = mappingJsonForCurrentValue.displayName || mappingJsonForCurrentValue.internalName;
            resultHtml = mappedValue;
        }
        return resultHtml;
    }

    static #doTokenReplacements = (templateHtml, arrayForTokenisation) => {
        let resultsHtml = templateHtml;
        if (arrayForTokenisation.length > 0) {
            arrayForTokenisation.forEach((tokenAndValue) => {
                resultsHtml = resultsHtml.replaceAll(tokenAndValue[0], tokenAndValue[1] + '');
            });
        }
        return resultsHtml;
    }
}