import Overlay from '../Overlay/Overlay.js';

export default class RecordOverlay extends Overlay {
    constructor(whereSelectorString, whatJson, optionalHeight) {
        super(whereSelectorString, whatJson, optionalHeight);
    }

    ShowAddRecordOverlay(callbackOnButtonClick, dbDataTableHandler, columnNamesMap) {
        let overlayJson = this.#buildAddHtml(this.Id, dbDataTableHandler, columnNamesMap);
        this.Render(overlayJson.title, overlayJson.body, overlayJson.buttons, callbackOnButtonClick);
        this.#updateEventHandlers();
    }

    ShowEditRecordOverlay(callbackOnButtonClick, dbDataTableHandler, columnNamesMap, selectedDataById) {
        let overlayJson = this.#buildEditHtml(this.Id, dbDataTableHandler, columnNamesMap, selectedDataById);
        this.Render(overlayJson.title, overlayJson.body, overlayJson.buttons, callbackOnButtonClick);
        this.#updateEventHandlers();
    }

    ShowDeleteRecordOverlay(callbackOnButtonClick, dbDataTableHandler,columnNamesMap, selectedDataById) {
        let overlayJson = this.#buildDeleteHtml(this.Id, dbDataTableHandler, columnNamesMap, selectedDataById);
        this.Render(overlayJson.title, overlayJson.body, overlayJson.buttons, callbackOnButtonClick);
        this.#updateEventHandlers();
    }

    async UpdateForeignFieldFromId(fieldName, selectedId) {
        console.log(fieldName + ': Selected ID: ' + selectedId);
        let jqInput = $('#' + this.Id).find('[data-column="' + fieldName + '"]');
        if (jqInput.length==1) {
            console.warn('This needs more work. Foreign fields need mapping to names and the jqInput might not be a HTML <input>');
            jqInput.val(selectedId);
        }
    }

    #updateEventHandlers() {
        console.log('RecordOverlay.#updateEventHandlers() - Do we still need this?');
    }

    #buildAddHtml(parentId, dbDataHandler, columnsMap) {
        console.log('RecordOverlay.#buildAddHtml()');
        let title = 'Add Record';
        let htmlBody = '<table><tbody>';
        let internalColNames = Object.keys(columnsMap);
        for (let j = 0; j < internalColNames.length; j++) {
            let colName = internalColNames[j];
            htmlBody += this.#htmlTableRowFieldInput(parentId, dbDataHandler, columnsMap, colName);
        }
        htmlBody += '</tbody>';
        htmlBody += '</table>';
        let buttons = '<button class="clickableElement btn overlayButton m-2" data-action="add">Add</button>';
        return {"title": title, "body": htmlBody, "buttons": buttons};
    }

    #buildEditHtml(parentId, dbDataHandler, columnsMap, selectedData) {
        let selectedIds = Object.keys(selectedData);
        if (selectedIds.length > 0) {
            let recJson = selectedData[selectedIds[0]];
            let title = 'Edit Record';
            let htmlBody = '<table><tbody>';
            let internalColNames = Object.keys(columnsMap);
            for (let j = 0; j < internalColNames.length; j++) {
                let colName = internalColNames[j];
                htmlBody += this.#htmlTableRowFieldInput(parentId, dbDataHandler, columnsMap, colName, recJson[colName]);
            }
            htmlBody += '</tbody>';
            htmlBody += '</table>';
            let buttons = '<button class="clickableElement btn overlayButton m-2" data-action="update" data-value="' + recJson.id + '">Save</button>';
            return {"title": title, "body": htmlBody, "buttons": buttons};
        }
    }

    #buildDeleteHtml(parentId, dbDataHandler, columnsMap, selectedData) {
        let selectedIds = Object.keys(selectedData);
        if (selectedIds.length > 0) {
            const DISABLED_INPUT_FIELD = true;
            let recJson = selectedData[selectedIds[0]];
            let title = 'Delete Record';
            let htmlBody = '<table><tbody>';
            let internalColNames = Object.keys(columnsMap);
            for (let j = 0; j < internalColNames.length; j++) {
                let colName = internalColNames[j];
                let colValue = recJson[colName];
                htmlBody += this.#htmlTableRowFieldInput(parentId, dbDataHandler, columnsMap, colName, colValue, DISABLED_INPUT_FIELD);
            }
            htmlBody += '</tbody>';
            htmlBody += '</table>';
            let buttons = '<button class="clickableElement btn overlayButton m-2" data-action="delete" data-value="' + recJson.id + '">Delete</button>';
            return {"title": title, "body": htmlBody, "buttons": buttons};
        }
    }

    #htmlTableRowFieldInput(parentId, dbDataHandler, columnsMap, colName, fieldValue, isDisabled) {
        let html = '';
        if ((typeof fieldValue === 'undefined') || (Object.is(fieldValue, null))) fieldValue = '';
        isDisabled = isDisabled || false;
        if ((colName == 'id') || (colName == 'updatedAt')) isDisabled = true;
        if (!dbDataHandler.IsFieldObscured(colName)) { // dont show obscured fields
            html = '<tr>';
            // required (*) and field name cell
            let requiredHtml = '';
            let isRequired = dbDataHandler.IsRequired(colName);
            if (isRequired) {
                console.log('IsRequired ' + colName + ': ' + isRequired );
                requiredHtml = '* ';
            }
            html += '<td style="text-align: right;">' + requiredHtml + columnsMap[colName] + ': </td>';
            // data entry cell plus extra cell where a chooser button is required
            html += '<td style="text-align: left;">';
            let elementId = null;
            if (dbDataHandler.IsFieldBoolean(colName)) {
                html += this.HtmlFormTrueFalseRadioButtons(parentId, colName, fieldValue, isDisabled);
                html += '</td><td>';
            } else if (dbDataHandler.IsFieldDate(colName)) {
                fieldValue = fieldValue.replace('Z', ''); // datetime-local requires an ISO date without the Z at the end
                html += this.HtmlFormDateTimeLocalInput(elementId, fieldValue, colName, isDisabled);
                html += '</td><td>';
            } else if (dbDataHandler.IsMappedField(colName)) {
                let mappingsForField = dbDataHandler.GetAllMappingsForField(colName);
                html += this.HtmlFormSelectDropdown(elementId, mappingsForField, fieldValue, colName, isDisabled);
                html += '</td><td>';
            } else if (dbDataHandler.IsForeignKey(colName)) {
                html += this.HtmlFormTextInput(elementId, fieldValue, colName, isDisabled);
                html += '</td><td>';
                if (!isDisabled) {
                    let dataAction = 'choose';
                    let label = 'Choose...';
                    html += this.HtmlButton(elementId, dataAction, colName, label, isDisabled);
                }
            } else if (dbDataHandler.IsFieldText(colName)) { // text fields are rendered as textareas, strings as input type=text
                let rows = 8;
                let cols = 30;
                html += this.HtmlFormTextArea(elementId, fieldValue, rows, cols, colName, isDisabled) 
                html += '</td><td>';
            } else if (dbDataHandler.IsFieldJson(colName)) { // text fields are rendered as textareas, strings as input type=text
                let rows = 8;
                let cols = 30;
                html += this.HtmlFormTextArea(elementId, fieldValue, rows, cols, colName, isDisabled) 
                html += '</td><td>';
            } else {
                html += this.HtmlFormTextInput(elementId, fieldValue, colName, isDisabled);
                html += '</td><td>';
            }
            html += '</td>';
            html += '</tr>';
        }
        return html;
    }

}