
// Builder for HTML tables used in ui/Misc/DataTable

export default class HtmlTableBuilder {
    
    static async Build(dbDataHandler, columnsMap, dataRows) {
        let htmlTable = '<table class="dataTable">';
        htmlTable += this.#buildTableHeader(columnsMap);
        htmlTable += await this.#buildTableDataRows(dbDataHandler, columnsMap, dataRows);
        htmlTable += '</table>';
        return htmlTable;
    }

    static #buildTableHeader(columnsMap) {
        let htmlHeader = '<thead><tr>';
        let internalColNames = Object.keys(columnsMap);
        internalColNames.forEach((internalColName) => {
            htmlHeader += '<th>' + columnsMap[internalColName] + '</th>';
        });
        htmlHeader += '</tr></thead>';
        return htmlHeader;
    }

    static async #buildTableDataRows(dbDataHandler, columnsMap, dataRows) {
        //console.log('--> buildTableDataRows');
        let htmlBody = '<tbody>';
        let internalColNames = Object.keys(columnsMap);
        let count = 0;
        for (let i = 0; i < dataRows.length; i++) {
            let rec = dataRows[i];
            let uniqueID = count;
            if ('id' in rec) uniqueID = rec.id;
            htmlBody += '<tr data-id="' + uniqueID + '">';
            for (let j = 0; j < internalColNames.length; j++) {
                let colName = internalColNames[j];
                htmlBody += '<td>';
                if (colName in rec) {
                    let fieldValue = rec[colName];
                    if (dbDataHandler.IsFieldObscured(colName)) {
                        fieldValue = '***';
                    } else if (fieldValue != '') {
                        if (dbDataHandler.IsMappedField(colName)) {
                            fieldValue = dbDataHandler.GetMappedValue(colName, fieldValue);
                        } else if (dbDataHandler.IsForeignKey(colName)) {
                            fieldValue = await dbDataHandler.ForeignKeyValue(colName, fieldValue);
                        } else if ((dbDataHandler.IsFieldDate(colName)) || (colName == 'updatedAt')) {
                            fieldValue = fieldValue.replace('T', ' ');
                            if (fieldValue.length>16) { 
                                // 2023-11-13T13:51:15.210Z
                                // 123456789012345678901234
                                fieldValue = fieldValue.substring(0,16);
                            }
                        }
                    }
                    htmlBody += fieldValue;
                }
                htmlBody += '</td>';
            }
            htmlBody += '</tr>';
            count++;
        }
        htmlBody += '</tbody>';
        return htmlBody;
    }

}