import UiBase from "../UiBase.js";
import Validator from "../../utils/Validator.js";
import Api from "../../utils/Api.js";
import Headline1 from './Headline1.html';
import Headline2 from './Headline2.html';
import Headline3 from './Headline3.html';
import CTA from './CTA.html';
import Features from './Features.html';
import Register from './Register.html';
import Footer from "../Footer/Footer.js";
import Snackbar from "../Snackbar/Snackbar.js";

export default class HomePage extends UiBase {

    static #HEADLINE_SHOW_DELAY = 1500;
    static #timerId = null;
    static #timerCallbacks = [];
    static #pageSpacingReduced = false;

    static Init = async () => {
        let jqContent = $('#Content');
        if (jqContent.length > 0) { // we are on the home page
            $('#Content').html(Headline1);
            this.#timerCallbacks = [this.AddHeadline2, this.AddHeadline3, this.AddCTA];
            let nextCB = this.#timerCallbacks[0];
            this.#timerId = setTimeout(nextCB, this.#HEADLINE_SHOW_DELAY);
        } else {
            if ($('footer').length > 0) {
                let uiFooter = new Footer();
                uiFooter.Render();
            }
        }
        this.#configureEventHandlers();
    }

    static async Show() {
        $('#Content').html('');
        await this.Init();
    }

    static AddHeadline2 = (optionalSkipTimedEvent) => {
        optionalSkipTimedEvent = optionalSkipTimedEvent || false;
        $('#Content').append(Headline2);
        if (!optionalSkipTimedEvent) {
            this.#timerCallbacks.shift();
            let nextCB = this.#timerCallbacks[0];
            this.#timerId = setTimeout(nextCB, this.#HEADLINE_SHOW_DELAY);
        }
        this.#configureEventHandlers();
    }
    static AddHeadline3 = (optionalSkipTimedEvent) => {
        optionalSkipTimedEvent = optionalSkipTimedEvent || false;
        $('#Content').append(Headline3);
        if (!optionalSkipTimedEvent) {
            this.#timerCallbacks.shift();
            let nextCB = this.#timerCallbacks[0];
            this.#timerId = setTimeout(nextCB, this.#HEADLINE_SHOW_DELAY);
        }
        this.#configureEventHandlers();
    }
    static AddCTA = () => {
        this.#timerCallbacks.shift();
        this.#timerId = null;
        $('#Headline1').addClass('mt-5 transition-all duration-300 ease-in-out');
        $('#Headline1').removeClass('mt-20');
        $('#Headline2').addClass('mt-5 transition-all duration-300 ease-in-out');
        $('#Headline2').removeClass('mt-10');
        $('#Headline3').addClass('mt-5 transition-all duration-300 ease-in-out');
        $('#Headline3').removeClass('mt-10');
        
        $('#Content').append(CTA);
        //$('#Tagline').addClass('mt-10 transition-all duration-300 ease-in-out');
        //$('#Tagline').removeClass('mt-20');
        $('#Content').append(Features);
        $('#Content').append(Register);
        setTimeout( () => {
            $('#FeaturesSection').addClass('featureHeadlineDoFadeIn');
            $('#FeaturesSection').addClass('mt-10 transition-all duration-300 ease-in-out');
            $('#FeaturesSection').removeClass('mt-52');
        },100);
        let uiFooter = new Footer();
        uiFooter.Render();
        this.#configureEventHandlers();
    }

    // internals

    static #configureEventHandlers = () => {
        let self = this;
        if (this.#timerCallbacks.length > 0) {
            $('body').off('click');
            $('body').on('click', function (evt) {
                self.#fastForwardHeadlines();
            });
        }
        $('#CtaButton').off('click');
        $('#CtaButton').on('click', async function (evt) {
            let jqBtn = $(this);
            let action = jqBtn.data('action');
            await self.#processButtonClick(action, jqBtn);
        });
        if (!this.#pageSpacingReduced) {
            $(window).on("scroll", function () {
                self.#reducePageSpacing();
            });
        } else {
            $(window).off("scroll");
        }
    }

    static #fastForwardHeadlines = () => {
        if (this.#timerId) clearTimeout(this.#timerId);
        let skipTimedEvent = true;
        for (let i = 0; i < this.#timerCallbacks.length; i++) {
            let nextCB = this.#timerCallbacks[i];
            nextCB(skipTimedEvent);
        }
        this.#timerCallbacks = [];
    }

    static #processButtonClick = async (action, jqBtn) => {
        console.log('> Main.#processButtonClick()', action);
        switch (action) {
            case 'learnMore':
                this.#reducePageSpacing();
                this.#smoothScroll('FeaturesSection');
                break;
            case 'registerEarlyAccess':
                let email = $('#RegisterEarlyAccessInput').val().trim();
                let isValidEmail = Validator.Email(email);
                if (isValidEmail) {
                    jqBtn.find('i').addClass('fa-beat');
                    let response = await fetch("/api/registerForEarlyAccess?email=" + email);
                    jqBtn.html('<i class="fa-solid fa-check"></i>');
                    $('#RegisterSection').fadeOut();
                    Snackbar.Show('Congratulations - you are registered for early access. We will be in touch soon.')
                } else {
                    alert('Invalid email address')
                }
                break;
            case 'sendContactMsg':
                let contactEmail = $('#ContactEmailInput').val().trim();
                if (!Validator.Email(contactEmail)) {
                    alert('Invalid email address')
                } else {
                    let contactMsg = $('#ContactMessageInput').val();
                    let sanitisedMsg = this.#sanitiseInput(contactMsg);
                    console.warn('Send contact message', contactEmail, sanitisedMsg);
                    let uri = '/api/contactMsg';
                    let jsonData = {email: contactEmail, msg: sanitisedMsg};
                    let response = await Api.Post(uri, jsonData)
                    console.log(response);
                    alert('Message sent')
                }
                break;
            default:
                console.warn('Unknown action: ' + action, jqBtn);
        }
    }

    static #reducePageSpacing = () => {
        if (!this.#pageSpacingReduced) {
            $('#Content').css('margin-top', '2vw')
            $('#VerticalSpacer1').slideUp();
            $('#VerticalSpacer2').slideUp();
            $('#VerticalSpacer3').slideUp();
            $('#VerticalSpacer4').slideUp();
            this.#pageSpacingReduced = true;
        }
    }

    static #smoothScroll = (targetId) => {
        var elmnt = document.getElementById(targetId);
        //elmnt.scrollIntoView({ behavior: 'smooth', block: 'start' });
        elmnt.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "nearest" });
    }

    static #sanitiseInput = (inputMsg) => {
        var temp = document.createElement("div");
        temp.textContent = inputMsg;
        return temp.innerHTML;
    }
}