import CircleOfTrust from '../dataObjects/CircleOfTrust.js';
import AdminPanelBase from '../../core/ui/panels/Admin/Shared/AdminPanelBase.js';

export default class CircleOfTrustAdmin extends AdminPanelBase {
    
    constructor(where, whatJson) {
        let buttons = '';
        whatJson = whatJson || {};
        const tableSummary = CircleOfTrust.GetTableSummary();
        whatJson.sectionTitle = tableSummary.displayNamePlural;
        whatJson.sectionDescription = tableSummary.description;
        super(where, whatJson, CircleOfTrust, buttons);
    }

}