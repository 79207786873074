import Api from '../../../utils/Api.js'
//import coreDataModel from './core.json';
import coreDataModel from '../../../../scaffolded/dataObjects/core.json';
import userDataModel from '../Users/users.json';

export default class DbTableBase {
    static CachedColumnNamesToDisplayNames = null;
    static CachedIntToNameMappings = null;
    static CachedForeignKeyColumns = null;
    static CachedForeignTableFieldNameIdResultValue = null;
    static CachedColumnDetails = null;

    static #ROUTES = {
        "GETBYID": "/apiCore/{TableName}/GetById/",
        "GETWHERE": "/apiCore/{TableName}/GetWhere",
        "GETALL": "/apiCore/{TableName}/GetAll",
        "CREATE": "/apiCore/{TableName}/PostCreate",
        "UPDATE": "/apiCore/{TableName}/PostUpdate/",
        "DELETE": "/apiCore/{TableName}/DeleteById/",
    };

    // API wrappers

    static async Get(queryJson) {
        return await this.GetAll(this.API_TABLE_NAME, queryJson);
    }

    static async GetWhere(queryJson) {
        const apiRoute = DbTableBase.#ROUTES.GETWHERE.replace('{TableName}', this.API_TABLE_NAME);
        const response = await Api.Get(apiRoute, queryJson);
        let jsonResults = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
        return jsonResults;
    }

    static async GetAll(tableName, queryJson) {
        const apiRoute = DbTableBase.#ROUTES.GETALL.replace('{TableName}', tableName);
        const response = await Api.Get(apiRoute, queryJson);
        let jsonResults = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
        return jsonResults;
    }

    static async GetById(tableName, id, queryJson) {
        tableName = tableName || this.API_TABLE_NAME;
        queryJson = queryJson || {};
        tableName = tableName.toLowerCase();
        const apiRoute = DbTableBase.#ROUTES.GETBYID.replace('{TableName}', tableName);
        const response = await Api.Get(apiRoute + id, queryJson);
        let jsonResults = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
        return jsonResults;
    }

    // Create, Update and Delete

    static async AddRecord(userData) {
        let resultJson = { "err": null, "data": null };
        let validationErrorMsgsArray = this.ValidateAddUserData(userData);
        if (validationErrorMsgsArray.length == 0) {
            const apiRoute = DbTableBase.#ROUTES.CREATE.replace('{TableName}', this.API_TABLE_NAME);
            const response = await Api.Post(apiRoute, userData);
            resultJson = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
        } else {
            validationErrorMsgsArray.unshift('<h3>Failed to add record</h3>');
            resultJson.err = validationErrorMsgsArray.join('<br />');
        }
        return resultJson;
    }

    static async UpdateRecord(userData) {
        let resultJson = { "err": null, "data": null };
        let validationErrorMsgsArray = this.ValidateAddUserData(userData);
        if (validationErrorMsgsArray.length == 0) {
            const apiRoute = DbTableBase.#ROUTES.UPDATE.replace('{TableName}', this.API_TABLE_NAME);
            const response = await Api.Post(apiRoute + userData.id, userData);
            resultJson = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
        } else {
            validationErrorMsgsArray.unshift('<h3>Failed to update record (' + userData.id + ')</h3>');
            resultJson.err = validationErrorMsgsArray.join('<br />');
        }
        return resultJson;
    }

    static async DeleteRecord(userData) {
        let resultJson = { "err": null, "data": null };
        if ('id' in userData) {
            const apiRoute = DbTableBase.#ROUTES.DELETE.replace('{TableName}', this.API_TABLE_NAME);
            const response = await Api.Post(apiRoute + userData.id);
            resultJson = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
        }
        if (!resultJson.data) {
            let errTitle = '<h3>Failed to delete record (' + userData.id + ')</h3>';
            resultJson.err = errTitle + resultJson.err;
        }
        return resultJson;
    }

    // user data validation

    static ValidateAddUserData(userData) {
        let errorMsgsArray = [];
        let requiredFieldsArray = this.AllRequiredFieldsJson();
        requiredFieldsArray.forEach((rqdFieldJson) => {
            if (rqdFieldJson.name in userData === false) {
                errorMsgsArray.push(rqdFieldJson.name + ' is required.')
            }
        });
        return errorMsgsArray;
    }

    static AllRequiredFieldsJson() {
        let allColNames = Object.keys(this.CachedColumnDetails);
        let requiredFieldsJson = [];
        allColNames.forEach((colname) => {
            let fieldJson = this.CachedColumnDetails[colname];
            if (('required' in fieldJson) && (fieldJson.required)) requiredFieldsJson.push(fieldJson);
        });
        return requiredFieldsJson;
    }

    // simple field queries

    static IsFieldBoolean(fieldName) {
        let fieldJson = this.GetFieldDetails(fieldName);
        if (('type' in fieldJson) && (fieldJson.type == 'BOOLEAN'))
            return true;
        else
            return false;
    }

    static IsFieldDate(fieldName) {
        let fieldJson = this.GetFieldDetails(fieldName);
        if (('type' in fieldJson) && (fieldJson.type == 'DATE'))
            return true;
        else
            return false;
    }

    static IsFieldText(fieldName) { // as opposed to a string TEXT are unlimited length so require a textarea
        let fieldJson = this.GetFieldDetails(fieldName);
        if (('type' in fieldJson) && (fieldJson.type == 'TEXT'))
            return true;
        else
            return false;
    }

    static IsFieldJson(fieldName) { // as opposed to a string TEXT are unlimited length so require a textarea
        let fieldJson = this.GetFieldDetails(fieldName);
        if (('type' in fieldJson) && (fieldJson.type == 'JSON'))
            return true;
        else
            return false;
    }

    static IsFieldObscured(fieldName) {
        let fieldJson = this.GetFieldDetails(fieldName);
        if (('obscureFromEditor' in fieldJson) && (fieldJson.obscureFromEditor))
            return true;
        else
            return false;
    }

    static IsRequired(fieldName) { // Options supported currently are: true, "Now", "RandomEncryptIfBlank"
        let fieldJson = this.GetFieldDetails(fieldName);
        if (('required' in fieldJson))
            return fieldJson.required;
        else
            return false;
    }

    static FieldDefaultValue(fieldName) {
        let fieldJson = this.GetFieldDetails(fieldName);
        if (('defaultValue' in fieldJson) && (fieldJson.defaultValue))
            return fieldJson.defaultValue;
        else
            return null;
    }

    static ForeignTableName(fieldName) {
        let fieldJson = this.GetFieldDetails(fieldName);
        if (('foreignKeyFor' in fieldJson) && (fieldJson.foreignKeyFor))
            return fieldJson.foreignKeyFor;
        else
            return null;
    }

    // return table name and description from the core xlsx2json data for use in the UI panels

    static GetDataModel(tableName) {
        if (tableName == 'User') {
            return userDataModel;
        } else {
            return coreDataModel;
        }
    }

    static GetTableSummary() {
        let dataModel = this.GetDataModel(this.JSON_TABLE_NAME);
        if ((!this.JSON_TABLE_NAME in dataModel.tablesSummary) || (dataModel.tablesSummary[this.JSON_TABLE_NAME] === 'undefined')) {
            console.warn('DbTableBase.GetTableSummary() - Missing table summary information' + this.JSON_TABLE_NAME + ' in core or user.json');
        }
        let tableSummary = dataModel.tablesSummary[this.JSON_TABLE_NAME][0] || { "displayNamePlural": "Unknown", "description": "" };
        return tableSummary
    }

    // simple (hard coded from JSON) integer value to name mappings

    static IsMappedField(fieldName) {
        let result = false;
        let mappedFields = this.GetTableValueMappings();
        if (fieldName in mappedFields) {
            result = true
        }
        return result;
    }

    static GetAllMappingsForField(fieldName) {
        let result = {};
        let mappedFields = this.GetTableValueMappings();
        if (fieldName in mappedFields) {
            result = mappedFields[fieldName];
        }
        return result;
    }

    static GetMappedValue(fieldName, fieldValue) {
        let result = fieldValue;
        let mappedFields = this.GetTableValueMappings();
        if (fieldName in mappedFields) {
            result = this.GetFieldValueMapped(mappedFields, fieldName, fieldValue);
        }
        return result;
    }

    static GetFieldValueMapped(mappedFields, fieldName, fieldValue) {
        let result = fieldValue;
        fieldValue = fieldValue + '';
        if (fieldName in mappedFields) {
            let fieldDetails = mappedFields[fieldName];
            if (fieldValue in fieldDetails) {
                result = fieldDetails[fieldValue];
            }
        }
        return result;
    }

    // caching internal column names to display names

    static GetTableColumnDisplayNames() {
        if (!this.CachedColumnNamesToDisplayNames) {
            let dataModel = this.GetDataModel(this.JSON_TABLE_NAME);
            this.CachedColumnNamesToDisplayNames = this.BuildCachedColumnNamesToDisplayNames(dataModel.tables[this.JSON_TABLE_NAME]);
        }
        return this.CachedColumnNamesToDisplayNames;
    }

    static BuildCachedColumnNamesToDisplayNames(fieldJsonArray) {
        let mappings = { "id": "Id" };
        fieldJsonArray.forEach((fieldJson) => {
            let fieldName = fieldJson.name;
            let fieldDisplayName = fieldJson.displayName;
            mappings[fieldName] = fieldDisplayName;
        });
        if ('updatedAt' in mappings === false) mappings['updatedAt'] = "Last Updated";
        return mappings;
    }

    // cached field mappings by field name, integers to display text, such as 10=Foo;20=Bar

    static GetTableValueMappings() {
        if (!this.CachedIntToNameMappings) {
            let dataModel = this.GetDataModel(this.JSON_TABLE_NAME);
            this.CachedIntToNameMappings = this.BuildSimpleIntToNameMappingsFromJson(dataModel.tables[this.JSON_TABLE_NAME]);
        }
        return this.CachedIntToNameMappings;
    }

    static BuildSimpleIntToNameMappingsFromJson(fieldJsonArray) {
        let mappings = {};
        if (fieldJsonArray) {
            let filteredArray = fieldJsonArray.filter((fieldJson) => ('mapping' in fieldJson));
            filteredArray.forEach((fieldJson) => {
                let fieldName = fieldJson.name;
                let mappingToDecode = fieldJson.mapping; // e.g. 10=Ten;20=Bigger;30=Lorem Ipsum - assumes vals are integers! Might have to rething that
                let arrayOfMappingElements = mappingToDecode.split(';');
                let fieldMappings = {};
                arrayOfMappingElements.forEach((mappingElementText) => { // e.g 10=Ten
                    let components = mappingElementText.split('=');
                    if (components.length == 2) {
                        let mappingInt = components[0];
                        fieldMappings[mappingInt] = components[1];
                    }
                });
                if (Object.keys(fieldMappings).length > 0) {
                    mappings[fieldName] = fieldMappings;
                }
            });
        }
        return mappings;
    }

    // cache to identify foreign key fields 

    static GetTableForeignKeyColumns() {
        if (!this.CachedForeignKeyColumns) {
            let dataModel = this.GetDataModel(this.JSON_TABLE_NAME);
            this.CachedForeignKeyColumns = this.BuildCachedForeignKeyColumns(dataModel.tables[this.JSON_TABLE_NAME]);
        }
        return this.CachedForeignKeyColumns;
    }

    static BuildCachedForeignKeyColumns(fieldJsonArray) {
        let mappings = {};
        if (fieldJsonArray) {
            let filteredArray = fieldJsonArray.filter((fieldJson) => ('foreignKeyFor' in fieldJson))
            filteredArray.forEach((fieldJson) => {
                let fieldName = fieldJson.name;
                let foreignKeyTable = fieldJson.foreignKeyFor;
                mappings[fieldName] = foreignKeyTable;
            });
        }
        return mappings;
    }

    // foreign key lookups

    static IsForeignKey(fieldName) {
        let result = false;
        let foreignKeyMappings = this.GetTableForeignKeyColumns();
        if (fieldName in foreignKeyMappings) {
            result = true;
        }
        return result;
    }

    static async ForeignKeyValue(fieldName, foreignKeyId) {
        let result = 'ID(' + foreignKeyId + ')';
        let foreignKeyMappings = this.GetTableForeignKeyColumns();
        if (fieldName in foreignKeyMappings) {
            let foreignTableName = foreignKeyMappings[fieldName];
            let resultFromCache = this.GetForeignKeyValueFromCache(fieldName, foreignKeyId);
            if (resultFromCache) {
                result = resultFromCache;
            } else {
                let responseJson = await this.GetById(foreignTableName, foreignKeyId);
                if (('err' in responseJson) && (responseJson.err)) {
                    console.warn(responseJson.err)
                } else {
                    let rec = responseJson.data;
                    if ('Name' in rec) {
                        result = rec.Name;
                        this.AddToForeignKeyValuesCache(fieldName, foreignKeyId, result);
                    } else if ('Email' in rec) {
                        result = rec.Email;
                        this.AddToForeignKeyValuesCache(fieldName, foreignKeyId, result);
                    }
                }
            }
        }
        return result;
    }

    // cache of foreign key lookups to reduce the overhead of API calls

    static GetForeignKeyValueFromCache(fieldName, id) {
        if (!this.CachedForeignTableFieldNameIdResultValue) this.CachedForeignTableFieldNameIdResultValue = {};
        if (fieldName in this.CachedForeignTableFieldNameIdResultValue) {
            if (id in this.CachedForeignTableFieldNameIdResultValue[fieldName]) {
                return this.CachedForeignTableFieldNameIdResultValue[fieldName][id];
            }
        }
    }

    static AddToForeignKeyValuesCache(fieldName, id, value) {
        if (!this.CachedForeignTableFieldNameIdResultValue) this.CachedForeignTableFieldNameIdResultValue = {};
        if (fieldName in this.CachedForeignTableFieldNameIdResultValue === false) {
            this.CachedForeignTableFieldNameIdResultValue[fieldName] = {};
        }
        this.CachedForeignTableFieldNameIdResultValue[fieldName][id] = value;
    }

    // cache of full field details 

    static GetFieldDetails(fieldName) {
        if (!this.CachedColumnDetails) {
            let dataModel = this.GetDataModel(this.JSON_TABLE_NAME);
            this.CachedColumnDetails = this.BuildAllFieldsDetailsJson(dataModel.tables[this.JSON_TABLE_NAME]);
        }
        if (fieldName in this.CachedColumnDetails) {
            return this.CachedColumnDetails[fieldName];
        } else {
            return {};
        }
    }

    static BuildAllFieldsDetailsJson(fieldsJsonArray) {
        let allFieldDetails = {};
        if (fieldsJsonArray) {
            fieldsJsonArray.forEach((fieldJson) => {
                let fieldName = fieldJson.name;
                allFieldDetails[fieldName] = fieldJson;
            });
        }
        return allFieldDetails;
    }

}