import LlmController from './LlmController.js';
/**
 * Static class for playing/speaking audio responses
 * @author Glen Birkbeck
 */
export default class AudioPlayer {

    static async ConvertTextAndPlay(msg) {
        //let mp3Blob = await this.#convertTextToMp3Uri(msg);
        let mp3Blob = await LlmController.ConvertResponseToMp3Uri(msg);
        await this.#playMp3Response(mp3Blob);
    }

    /*static async #convertTextToMp3Uri(msg) {
        let apiResultsJson = null;
        let token = Auth.GetJwtAccessToken();
        apiResultsJson = await axios.post('/llmapi/TextToSpeech', {
            message: msg
        }, {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        console.log('Received for mp3: ', apiResultsJson);
        return apiResultsJson.data;
    }*/

    static async #playMp3Response(mp3ResponseData) {
        // Get the audio element
        const audio = document.getElementById('AudioPlayer');
        // Set the src attribute to the MP3 URL
        audio.src = mp3ResponseData.uri;
        // Add event listener for when the audio is loaded
        audio.addEventListener('loadeddata', () => {
            // Play the audio
            audio.play();
        });
        // Add error handling in case the audio fails to load
        audio.addEventListener('error', (event) => {
            console.error('Error loading audio:', event);
        });

    }
}