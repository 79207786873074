import Api from './Api.js';
import CurrentUser from '../data/LocalStorage/CurrentUser.js';

export default class Auth {
    static #MIN_PASS_LENGTH = 4; // change this!
    static #ROUTES = {
        "WHOAMI": "/auth/other/whoami",
        "REGISTER": "/auth/local/register",
        "LOGIN": "/auth/local/login",
        "GOOGLELOGIN": "/auth/google/",
        "FORGOT_PWD": "/auth/other/sendForgotPasswordEmailLink",
        "LOGOUT": "/auth/other/logout",
        "LOGOUT_ALL": "/auth/other/logoutAll",
        "UPDATE": "/auth/other/updateAccount",
        "RESET_PWD": '/auth/other/resetPassword',
        "SEND_EMAIL_VERIFICATION_LINK": '/auth/other/sendVerifyEmailLink',
    };

    /*static GetJwtAccessToken() {
        let jwtToken = null
        const cookieValue = document.cookie.match(/myCookie=([^;]*)/);
        if (cookieValue) {
            jwtToken = decodeURIComponent(cookieValue[1]);
        }
        return jwtToken;
    }*/

    static GetUserDetails = async () => {
        let detailsJson = { "email": null, "name": null, "rawData": null };
        let whoAmIJson = await this.WhoAmI();
        let hasUserEverLoggedIn = await CurrentUser.HasUserEverLoggedIn();
        if (!hasUserEverLoggedIn) {
            console.log('User has never logged in according to indexedDB!');
        }
        console.log('WhoAmIJson: ', whoAmIJson);
        if (!whoAmIJson.err) { // in api is cached we should always end up here unless cache has been cleared or user has never logged in
            detailsJson.email = whoAmIJson.data.email;
            detailsJson.name = whoAmIJson.data.nickname;
            detailsJson.rawData = whoAmIJson.data;
            if (detailsJson.email && detailsJson.name) {
                //this.SetUserDetails(detailsJson.email, detailsJson.name);
                await CurrentUser.Details(detailsJson);
            }
        } else {
            detailsJson = CurrentUser.Details();
        }
        return detailsJson;
    }

    static WhoAmI = async () => {
        let whoAmIJson = null;
        //const responseJson = await Api.Post(Auth.#ROUTES.WHOAMI);
        const responseJson = await Api.Get(Auth.#ROUTES.WHOAMI);
        whoAmIJson = Api.JsonResponseHandler(responseJson, API.EXPECTED_REPONSE_CODES.OK);
        if (!whoAmIJson.err) await CurrentUser.Details(whoAmIJson.data);
        return whoAmIJson;
    }

    static TestRefreshToken = async () => {
        const responseJson = await Api.UseRefreshToken();
        //resultJson = Api.JsonResponseHandler(responseJson, Api.EXPECTED_REPONSE_CODES.OK);
        return responseJson;
    }

    static Register = async (email, pwd, pwd2) => {
        let registerJson = { err: null, data: null };
        if ((!email) && (!pwd)) {
            registerJson.err = 'Email and password are required';
        } else if (email.indexOf('@') == -1) {
            registerJson.err = 'Email invalid';
        } else if (pwd.length < this.#MIN_PASS_LENGTH) {
            registerJson.err = 'Password must be at least 8 characters long';
        } else if (pwd != pwd2) {
            registerJson.err = 'Passwords do not match';
        } else {
            const data = {
                "email": email,
                "password": pwd
            };
            const response = await Api.Post(Auth.#ROUTES.REGISTER, data);
            registerJson = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
            if (registerJson && ('data' in registerJson) && (registerJson.data) && (Object.keys(registerJson.data).length > 0)) {
                let dataJson = registerJson.data;
                await CurrentUser.Details(dataJson);

            } else {
                console.log('registerJson does not contain any data: ', registerJson);
            }
        }
        return registerJson;
    }

    static Login = async (email, pwd) => {
        let loginJson = { err: null, data: null };
        if ((!email) && (!pwd)) {
            alert('Email and password are required');
        } else if (email.indexOf('@') == -1) {
            alert('Email invalid address');
        } else {
            let clientKey = await CurrentUser.ClientKey();
            const data = {
                "clientKey": clientKey,
                "email": email,
                "password": pwd
            };
            const response = await Api.Post(Auth.#ROUTES.LOGIN, data);
            loginJson = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
            if (loginJson && ('data' in loginJson) && (loginJson.data) && (Object.keys(loginJson.data).length > 0)) {
                let dataJson = loginJson.data;
                await CurrentUser.Details(dataJson);
            } else {
                console.log('loginJson does not contain any data: ', loginJson);
            }
        }
        return loginJson;
    }

    static GoogleAuthComplete = async (clientKey, refreshToken, userId, email, name) => {
        let userJson = {
            "id": userId,
            "clientKey": clientKey,
            "refreshToken": refreshToken,
            "email": email,
            "name": name
        }
        await CurrentUser.Details(userJson);
        await this.WhoAmI(); // call whoami to get the rest of the user data!
        let pageTarget = '/auth/google/complete';

        window.location.href = pageTarget;
    }

    static Logout = async () => {
        let clientKey = await CurrentUser.ClientKey();
        let refreshToken = await CurrentUser.RefreshToken();
        let dataJson = { "clientKey": clientKey, "token": refreshToken };
        const response = await Api.Post(Auth.#ROUTES.LOGOUT, dataJson);
        await CurrentUser.Logout();
    }

    static LogoutAll = async () => {
        let clientKey = await CurrentUser.ClientKey();
        let refreshToken = await CurrentUser.RefreshToken();
        let dataJson = { "clientKey": clientKey, "token": refreshToken };
        const response = await Api.Post(Auth.#ROUTES.LOGOUT_ALL, dataJson);
        await CurrentUser.Logout();
    }

    static UpdateAccount = async (userId, email, nickname) => {
        const data = {
            "userId": userId,
            "email": email,
            "nickname": nickname
        };
        const response = await Api.Post(Auth.#ROUTES.UPDATE, data);
        let resultsJson = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
        let userDataJson = {
            "id": userId,
            "email": email,
            "name": nickname
        };
        await CurrentUser.Details(userDataJson);
        return resultsJson;
    }

    static ForgotPassword = async (email) => {
        const data = {
            "email": email
        };
        const response = await Api.Post(Auth.#ROUTES.FORGOT_PWD, data);
        let resultsJson = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
        return resultsJson;
    }

    static ResetPassword = async (userId, originalPwd, newPwd, verifyNewPwd) => {
        console.log('-> reset password ', userId, originalPwd, newPwd, verifyNewPwd);
        let resultsJson = { "err": null, "data": null };
        if (newPwd != verifyNewPwd) {
            resultsJson.err = "New password and verification password do not match";
        } else {
            const data = {
                "userId": userId,
                "oldPwd": originalPwd,
                "newPwd": newPwd
            };
            const response = await Api.Post(Auth.#ROUTES.RESET_PWD, data);
            resultsJson = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
        }
        return resultsJson;
    }

    static ResendEmailVerificationLink = async (userId) => {
        console.log('-> resend email verification link', userId);
        const data = {
            "userId": userId
        };
        const response = await Api.Post(Auth.#ROUTES.SEND_EMAIL_VERIFICATION_LINK, data);
        let resultsJson = Api.JsonResponseHandler(response, Api.EXPECTED_REPONSE_CODES.OK);
        return resultsJson;
    }

}

